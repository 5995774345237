import React, { useEffect, useRef, useState } from "react";
import profile from "../../assets/img/noProfile.jpg";
import { Icon } from "@iconify/react";
import Input from "../input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useResponsive } from "../../themes/useResponsive";
import { useDispatch } from "react-redux";
import { addRentalUserByAdmin } from "store/slice/dashboard";
export default function AddRentalUserProfile(props) {
  const { fetchRentalList } = props;
  const [RentalProfileData, setRentalProfileData] = useState({});
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const closeRef = useRef();
  const handleRentalProfile = () => {
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    if (!RentalProfileData?.firstName) {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        firstNameErr: "Please enter your first name",
      }));
      return;
    }
    if (RentalProfileData?.firstName.trim().length == " ") {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        firstNameErr: "Please enter your first name",
      }));
      return;
    }
    if (!RentalProfileData?.lastName) {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        lastNameErr: "Please enter your last name",
      }));
      return;
    }
    if (RentalProfileData?.lastName.trim().length == " ") {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        lastNameErr: "Please enter your last name",
      }));
      return;
    }
    if (!RentalProfileData?.email) {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        emailErr: "Please enter your email address",
      }));
      return;
    } else if (!RentalProfileData?.email.includes("@")) {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        emailErr: "Please enter a valid email address",
      }));
      return;
    } else if (!RentalProfileData?.email.includes(".")) {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        emailErr: "Please enter a valid email address",
      }));
      return;
    } else if (!validEmail.test(RentalProfileData?.email)) {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        emailErr: "Please enter a valid email address",
      }));
      return;
    }
    if (!RentalProfileData?.phoneNumber) {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        phoneNumberErr: "Please enter your phone number",
      }));
      return;
    }
    if (!RentalProfileData?.password) {
      setRentalProfileData((RentalProfileData) => ({
        ...RentalProfileData,
        passwordErr: "Please enter your password",
      }));
      return;
    }
    let request = {
      firstName: RentalProfileData?.firstName,
      lastName: RentalProfileData?.lastName,
      email: RentalProfileData?.email,
      password: RentalProfileData?.password,
      countryCode: RentalProfileData?.countryCode,
      phoneNumber: RentalProfileData?.phoneNumber,
      language: "en",
    };
    dispatch(addRentalUserByAdmin(request, closeRef, fetchRentalList));
    setRentalProfileData({
      firstName:"",
      lastName:"",
      email:"",
      password:"",
      phoneNumber:"",
      countryCode:""

    });
  };
  return (
    <>
      <div
        className="modal fade bd-example-modal-lg"
        id="exampleModalCenterprofile"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div
            className={`modal-content py-2 ${
              screenType != "desktop" ? "" : "px-4"
            } `}
          >
            <div className="modal-header">
              <div className="row w-100">
                <div className="col-lg-8 col-md-6 col-sm-12">
                  {" "}
                  <h5 className="modal-title" id="exampleModalCenterprofile">
                    Add New Rentals
                  </h5>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    className="smallBtn"
                    style={{ width: "100%" }}
                    onClick={handleRentalProfile}
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    ref={closeRef}
                    className="smallBtn"
                    style={{ width: "100%"}}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
              </div>
              </div>

              
            </div>
            <div className="modal-body">
              <div className="row">
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Input
                          maxLength={50}
                          onChange={(val) => {
                            setRentalProfileData((RentalProfileData) => ({
                              ...RentalProfileData,
                              firstNameErr: "",
                              firstName: val,
                            }));
                          }}
                          value={RentalProfileData?.firstName}
                          placeholder={"First Name"}
                          icon={"fluent:person-28-regular"}
                          formError={RentalProfileData?.firstNameErr}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Input
                          maxLength={50}
                          onChange={(val) => {
                            setRentalProfileData((RentalProfileData) => ({
                              ...RentalProfileData,
                              lastNameErr: "",
                              lastName: val,
                            }));
                          }}
                          value={RentalProfileData?.lastName}
                          placeholder={"Last Name"}
                          icon={"fluent:person-28-regular"}
                          formError={RentalProfileData?.lastNameErr}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group ">
                    <Input
                      maxLength={30}
                      onChange={(val) => {
                        setRentalProfileData((RentalProfileData) => ({
                          ...RentalProfileData,
                          emailErr: "",
                          email: val,
                        }));
                      }}
                      value={RentalProfileData?.email}
                      placeholder={"Email Address"}
                      icon={"mdi-light:email"}
                      formError={RentalProfileData?.emailErr}
                    />
                  </div>
                  <div className="form-group first ">
                    <div className="d-flex">
                      <div
                        style={{
                          background: "#f6f6f8 ",
                          paddingLeft: "0.7rem",
                          paddingTop: "0.7rem",
                        }}
                      >
                        <Icon
                          icon="ph:phone-call-light"
                          height={30}
                          color="#757c83"
                        />
                      </div>
                      <PhoneInput
                        style={{
                          width: "100%",
                        }}
                        placeholder={"(00) 0000-0000"}
                        country={"na"}
                        value={RentalProfileData?.phone}
                        inputStyle={{
                          width: "100%",
                          // border: "none",
                          // height: "51px ",
                          // fontSize: "19px",
                        }}
                        onChange={(value, inputdata) => {
                          setRentalProfileData((RentalProfileData) => ({
                            ...RentalProfileData,
                            phoneNumberErr: "",
                            phone: value,
                            phoneNumber: value.slice(
                              inputdata?.dialCode?.length
                            ),
                            countryCode: inputdata?.dialCode,
                          }));
                        }}
                        inputClass="telInput"
                      />
                    </div>
                    <div
                      style={{
                        color: "red",
                        fontSize: 13,
                        marginTop: "0.5rem",
                        paddingLeft: 5,
                      }}
                      className="text-error"
                    >
                      {RentalProfileData?.phoneNumberErr &&
                        RentalProfileData?.phoneNumberErr}
                    </div>
                  </div>
                  <div className="form-group ">
                    <Input
                      type={!RentalProfileData?.showPass ? "password" : "text"}
                      endIcon={
                        RentalProfileData?.showPass
                          ? "formkit:eye"
                          : "humbleicons:eye-off"
                      }
                      passwordClick={() =>
                        setRentalProfileData((RentalProfileData) => ({
                          ...RentalProfileData,
                          showPass: !RentalProfileData?.showPass,
                        }))
                      }
                      onChange={(val) => {
                        setRentalProfileData((RentalProfileData) => ({
                          ...RentalProfileData,
                          passwordErr: "",
                          password: val,
                        }));
                      }}
                      maxLength={12}
                      value={RentalProfileData?.password}
                      placeholder={"Enter Password"}
                      icon={"circum:lock"}
                      formError={RentalProfileData?.passwordErr}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
