import React, { useEffect, useRef, useState } from "react";
import OwnerHeader from "../../../layout/dashboardLayout/header";
import CountBox from "../countBox";
import { useResponsive } from "../../../themes/useResponsive";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import totalrevenueIcon from "assets/img/totalrevenue.png";
import totalrentalIcon from "assets/img/totalrentals.png";
import totaluserIcon from "assets/img/totaluser.png";
import "../../../themes/style.css";
import { DateRange } from "react-date-range";
import SmallButton from "components/button/smallButton";
import { dashboardTotalCounts } from "store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { getcouponsListByAdmin } from "store/slice/coupons";
import { Dropdown, Modal, Button, Spinner } from "react-bootstrap";
import { deleteCouponByAdmin } from "store/slice/coupons";
import InfiniteScroll from "react-infinite-scroll-component";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import moment from "moment";
import { getTheGraph } from "store/slice/dashboard";
import ChartApex from "./chart";
let limit = 10;
export default function Dashboard() {
  const { screenType } = useResponsive();
  ChartJS.register(ArcElement, Tooltip, Legend);
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const { couponsList } = useSelector((state) => state.coupons);
  const { totalCounts } = useSelector((state) => state.dashboard);
  console.log("couponsList.", couponsList);
  const handleDelete = (id) => {
    setCouponToDelete(id);
    setShowDeleteConfirmation(true);
  };
  const handleScroll = () => {
    setIsloading(true);
    limit = limit + 10;
    fetchList(limit);
  };



  const handleDeleteConfirmed = () => {
    if (couponToDelete) {
      dispatch(
        deleteCouponByAdmin({ couponId: couponToDelete, language: "en" })
      );
      setShowDeleteConfirmation(false);
    }
  };

  const calendarRef = useRef(null);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };
  useEffect(() => {
    getTotalCounts();
  }, []);

  const getTotalCounts = () => {
    let request = {
      // totalRevenue: 0,
      // totalRental: 32,
      // totalUser: 66,
      // commissionPaid: 0,
    };

    dispatch(dashboardTotalCounts(request));
  };

  useEffect(() => {
    fetchList();
  }, [filterData]);

  useEffect(() => {
    if (state?.length > 0) {
      if (state[0]?.startDate) {
        getstatistics(
          moment(state[0]?.startDate)?.format("DD-MM-YYYY"),
          moment(state[0]?.endDate)?.format("DD-MM-YYYY")
        );
      }
    }
  }, [state]);

  const fetchList = (perPage, startDate, endDate) => {
    let request = {
      page: 1,
      perPage: perPage ? perPage : 10,
      language: "en",
      limit: 10000,
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      search: "",
      sortByDate: filterData.sortByDate,
    };
    dispatch(getcouponsListByAdmin(request));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("totalCounts", totalCounts);
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <Icon
        icon="entypo:dots-two-vertical"
        color="#132649"
        style={{ cursor: "pointer" }}
      />
    </a>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const getstatistics = (startDate, endDate) => {
    let request = {
      language: "en",
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
    };

    dispatch(getTheGraph(request));
  };
  return (
    <>
      <OwnerHeader title={"Dashboard"} />
      <div
        className="my-5"
        style={{ height: "auto", marginRight: "-1rem", paddingLeft: "1rem" }}
      >
        <div className="row">
          <div className={screenType != "desktop" ? "col-12" : "col-8"}>
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 dashBoard-col">
                <CountBox
                  title={"Total Revenue"}
                  count={totalCounts?.totalRevenue}
                  bg={"#FFEAEA"}
                  image={totalrevenueIcon}
                  onClick={() => navigate("/total-revenue")}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12  dashBoard-col">
                <CountBox
                  title={"Total Rentals"}
                  count={totalCounts?.totalRental}
                  bg={"#ECFFE0"}
                  image={totalrentalIcon}
                  onClick={() => navigate("/total-rentals")}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12  dashBoard-col">
                <CountBox
                  title={"Total User"}
                  count={totalCounts?.totalUser}
                  bg={"#FCEBFF"}
                  image={totaluserIcon}
                  onClick={() => navigate("/total-users")}
                  style={{ cursor: "pointer" }}
                />
              </div>
              {/* <div className="col-lg-3 col-md-6 col-sm-12  dashBoard-col">
                <CountBox
                  title={"Comission Paid"}
                  count={totalCounts?.commissionPaid}
                  bg={"#FFF2DF"}
                  image={myCarIcon}
                  onClick={() => navigate("/total-comission")}
                  style={{ cursor: "pointer" }}
                />
              </div> */}
            </div>
            <div
              className="row align-items-center"
              style={{ marginTop: screenType == "desktop" ? "1.2rem" : "" }}
            >
              <div
                className="col-lg-4 col-md-4 col-sm-4 fs-2  carOwner-tab-title"
                style={{ marginTop: screenType == "MOBILE" ? "2.2rem" : "" }}
              >
                Statistics
              </div>
              <div
                className="col-lg-8 col-md-8 col-sm-8 d-flex justify-content-end "
                style={{ marginTop: screenType == "MOBILE" ? "-2rem" : "" }}
              >
                <form className="form-search form-inline rounded-5">
                  {/* <div className="dropdown">
                    <button
                      style={{ width: 150 }}
                      className="sort-List dropdown-toggle dropdown-toggle-split"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <label style={{ fontSize: "16px" }}>Monthly</label>
                      <Icon icon={"formkit:down"} width={"20px"} />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setFilterData((filterData) => ({
                            ...filterData,
                            sortByDate: -1,
                          }));
                        }}
                      >
                        New-Old
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setFilterData((filterData) => ({
                            ...filterData,
                            sortByDate: 1,
                          }));
                        }}
                      >
                        Old-New
                      </a>
                    </div>
                  </div> */}
                  <div
                    className="checklist bg-white rounded-4 ml-2 d-flex justify-content-center align-items-center"
                    style={{ height: 40, width: 40 }}
                  >
                    <Icon
                      icon="octicon:calendar-16"
                      style={{ color: "black", cursor: "pointer" }}
                      onClick={handleIconClick}
                    />
                    {showCalendar && (
                      <div
                        ref={calendarRef}
                        style={{
                          position: "absolute",
                          top: "30px",
                          left: "0",
                          zIndex: 5,
                        }}
                      >
                        {/* <Calendar onChange={handleCalendarChange} value={date} /> */}
                        <DateRange
                          editableDateInputs={true}
                          onChange={(item) => setState([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={state}
                        />
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <ChartApex getstatistics={getstatistics} />
            {/* <div className="noData" style={{ height: "50vh" }}>
            No Data Found
          </div> */}
          </div>

          {screenType === "desktop" && (
            <div className="col-lg-4 col-md-4">
              <div
                className="py-4 px-3"
                id="scrollableDiv"
                style={{
                  background: "white",
                  borderRadius: 15,
                  overflow: "auto",
                  height: "89vh",
                }}
              >
                <div className="d-flex justify-content-between pb-3">
                  <div className="carOwner-heading">Coupons</div>
                  <SmallButton
                    onClick={() => {
                      navigate("/create-coupons");
                    }}
                    title={"Create"}
                    width={100}
                  />
                </div>
                <InfiniteScroll
                  dataLength={
                    couponsList?.totalCount 
                      ? couponsList?.totalCount 
                      : 0
                  }
                  next={handleScroll}
                  hasMore={!couponsList?.totalCount}
                  // scrollableTarget="scrollableDiv"
                >
                  {couponsList?.data?.formattedCoupons?.length >= 1 ? (
                    <>
                      {couponsList?.data?.formattedCoupons?.map(
                        (data, index) => {
                          return (
                            <>
                              <div className="col-lg-12 col-md-12 ">
                                <div
                                  className="card mt-2 rounded-4"
                                  style={{
                                    background:
                                      "linear-gradient(to right, #fae9d0a6, #dff8ed)",

                                    border: "none",
                                  }}
                                >
                                  <div className="d-flex justify-content-between mt-3">
                                    <div className="ml-3 deal">
                                      {data?.couponTitle}
                                    </div>
                                    <div className="mr-4">
                                      <div className="myCar-deleteIcon1">
                                        <Dropdown align={"end"}>
                                          <Dropdown.Toggle
                                            as={CustomToggle}
                                            id="dropdown-custom-components"
                                          >
                                            Custom toggle
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu as={CustomMenu}>
                                            <Dropdown.Item
                                              onClick={() => {
                                                navigate(
                                                  `/update-coupons/${data?.id}`
                                                );
                                              }}
                                            >
                                              <span
                                                className="pl-4"
                                                style={{
                                                  fontFamily:
                                                    "Circular Std Black",
                                                }}
                                              >
                                                Update
                                              </span>
                                            </Dropdown.Item>

                                            <Dropdown.Item
                                              onClick={() => {
                                                handleDelete(data?.id);
                                              }}
                                            >
                                              <span
                                                className="pl-4"
                                                style={{
                                                  fontFamily:
                                                    "Circular Std Black",
                                                }}
                                              >
                                                Delete
                                              </span>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ml-3 off">
                                    <span>
                                      {data?.discountType === "flat" ? (
                                        <span
                                          style={{
                                            color: "#8892A3",
                                            fontFamily: "Circular Std Book",
                                            fontSize: "1.5rem",
                                          }}
                                        >
                                          N$
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {data?.discountType === "flat"
                                        ? `${data?.discountAmount}`
                                        : `${data?.discountAmount}%`}
                                      &nbsp;Off
                                    </span>
                                    {/* {data?.discountAmount}%Off */}
                                  </div>
                                  <div className="d-flex justify-content-between mt-3">
                                    <div className="ml-2 code mt-1">
                                      Code : {data?.couponCode}
                                    </div>
                                    <div className="mr-3 expDate ">
                                      <span
                                        style={{
                                          fontFamily: "Circular Std Mixed",
                                          color: " #6c757d",
                                          fontSize: "0.8rem",
                                        }}
                                      >
                                        Exp.Date &nbsp;
                                      </span>
                                      {moment(data?.expiryDate).format(
                                        "MMM Do, YYYY"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                      {isLoading && <Spinner />}
                    </>
                  ) : (
                    <div className="noData" style={{ height: "70vh" }}>
                      No Data Found
                    </div>
                  )}
                </InfiniteScroll>
              </div>
            </div>
          )}
          <Modal
            show={showDeleteConfirmation}
            onHide={() => setShowDeleteConfirmation(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this coupon?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirmed}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}
