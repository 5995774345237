import React, { useEffect, useState } from "react";
import OwnerHeader from "layout/dashboardLayout/header";
import { Icon } from "@iconify/react";
import { useResponsive } from "themes/useResponsive";
import { useNavigate } from "react-router-dom";
import { clearCouponDetail, getcouponsListByAdmin } from "store/slice/coupons";
import { useDispatch, useSelector } from "react-redux";
import { deleteCouponByAdmin } from "store/slice/coupons";
import { Dropdown, Modal, Button, Spinner } from "react-bootstrap";
import ColoredButton from "components/button/colorFullButtons";
import PopOverSpinner from "components/popOverSpinner";
import moment from "moment";

const Coupons = () => {
  const { screenType } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { couponsList } = useSelector((state) => state.coupons);
  const [limit, setLimit] = useState(9);
  const [isLoading, setIsLoading] = useState(false);
  console.log("total", couponsList);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState(null);

  const handleDelete = (id) => {
    console.log("id", id);
    setCouponToDelete(id);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirmed = () => {
    if (couponToDelete) {
      dispatch(
        deleteCouponByAdmin({ couponId: couponToDelete, language: "en" })
      );
      setShowDeleteConfirmation(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = (perPage) => {
    setIsLoading(true);
    let request = {
      page: 1,
      perPage: perPage ? perPage : limit,
      language: "en",
    };
    dispatch(getcouponsListByAdmin(request, setIsLoading));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <Icon
        icon="entypo:dots-two-vertical"
        color="#132649"
        style={{ cursor: "pointer" }}
      />
    </a>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <>
      <>
        <OwnerHeader title={"Coupons"} />
        <div className="d-flex justify-content-between">
          <div
            className="fs-2 mt-5 carOwner-tab-title col-md-4 col-sm-6"
            style={{ marginRight: "auto" }}
          >
            History
          </div>
          {screenType === "MOBILE" ? (
            <div
              className="mt-4"
              type="button"
              style={{
                background: "#FFBB51",
                borderRadius: 35,
                height: 40,
                width: 40,
                position: "absolute",
                top: "3.3rem",
                right: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon
                onClick={() => navigate("/create-coupons")}
                icon="material-symbols-light:library-add"
                color="white"
                height={20}
              />
            </div>
          ) : (
            <div>
              <button
                onClick={() => {
                  dispatch(clearCouponDetail());
                  navigate("/create-coupons");
                }}
                className="btn btn-warning mt-5 "
                type="button"
                style={{
                  fontFamily: "Circular Std Black",
                  width: "20rem",
                }}
              >
                Create Coupon
              </button>
            </div>
          )}
        </div>
        {isLoading && !couponsList?.formattedCoupons ? <PopOverSpinner /> : ""}

        <div className="row">
          {couponsList?.data?.formattedCoupons?.length >= 1 ? (
            <>
              {couponsList?.data?.formattedCoupons?.map((data, index) => {
                return (
                  <>
                    <div className="col-lg-4 col-md-12 col-sm-12 ">
                      <div
                        className="card mt-5 rounded-4"
                        style={{
                          background:
                            "linear-gradient(to right, #fae9d0a6, #dff8ed)",
                          height: "9rem",
                          border: "none",
                        }}
                      >
                        <div className="d-flex justify-content-between mt-3">
                          <div className="ml-3 deal">{data?.couponTitle}</div>
                          <div className="mr-4">
                            <div className="myCar-deleteIcon1">
                              <Dropdown align={"end"}>
                                <Dropdown.Toggle
                                  as={CustomToggle}
                                  id="dropdown-custom-components"
                                >
                                  Custom toggle
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}>
                                  <Dropdown.Item
                                    onClick={() => {
                                      navigate(`/update-coupons/${data?.id}`);
                                    }}
                                  >
                                    <span
                                      className="pl-4"
                                      style={{
                                        fontFamily: "Circular Std Black",
                                      }}
                                    >
                                      Update
                                    </span>
                                  </Dropdown.Item>
                                  <hr />
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleDelete(data?.id);
                                    }}
                                  >
                                    <span
                                      className="pl-4"
                                      style={{
                                        fontFamily: "Circular Std Black",
                                      }}
                                    >
                                      Delete
                                    </span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className="ml-3 off">
                          <span>
                            {data?.discountType === "flat" ? (
                              <span
                                style={{
                                  color: "#8892A3",
                                  fontFamily: "Circular Std Book",
                                  fontSize: "1.5rem",
                                }}
                              >
                                N$
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.discountType === "flat"
                              ? `${data?.discountAmount}`
                              : `${data?.discountAmount}%`}
                              &nbsp;Off
                          </span>

                          {/* {data?.discountType === "flat" ? `N$ ${data?.discountAmount}% Off` : `${data?.discountAmount}% Off`} */}
                          {/* {data?.discountAmount}%Off */}
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                          <div className="ml-2 code mt-1">
                            Code : {data?.couponCode}
                          </div>
                          <div className="mr-3 expDate ">
                            <span
                              style={{
                                fontFamily: "Circular Std Mixed",
                                color: " #6c757d",
                                fontSize: "0.8rem",
                              }}
                            >
                              Exp.Date &nbsp;
                            </span>
                            {moment(data?.expiryDate).format("MMM Do, YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              <div className="d-flex justify-content-center mt-5">
                {isLoading ? (
                  <Spinner animation="border" style={{ color: "#6CD2C2" }} />
                ) : (
                  couponsList?.totalCount > limit && (
                    <ColoredButton
                      onClick={() => {
                        let perPage = limit + 12;
                        setLimit(perPage);
                        fetchList(perPage);
                      }}
                      title="View More"
                      style={{ background: "#6CD2C2", width: "130px" }}
                    />
                  )
                )}
              </div>
            </>
          ) : (
            <div className="noData" style={{ height: "80vh" }}>
              No Data Found
            </div>
          )}
        </div>
      </>
      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this coupon?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirmed}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Coupons;
