import { createSlice } from "@reduxjs/toolkit";
import { RENTEDWHEELSAPI } from "../../services/apiConstant";
import api from "../../services";
import toast from "react-hot-toast";

const slice = createSlice({
  name: "blogsData",
  initialState: {
    blogByAdmin: [],
    allBlogsList: [],
    descriptionOfBlog: [],
    updationOfBlog: [],
  },
  reducers: {
    addBlogsByAdminSuccess: (state, action) => {
      state.blogByAdmin = action.payload;
    },
    getBlogsByAdminSuccess: (state, action) => {
      state.allBlogsList = action.payload;
    },
    blogDescriptionSuccess: (state, action) => {
      state.descriptionOfBlog = action.payload;
    },
    updateBlogByAdminSuccess: (state, action) => {
      state.updationOfBlog = action.payload;
    },
    clearBlogDetailSuccess: (state, action) => {
      state.descriptionOfBlog = action.payload;
    },
    deleteBlogByAdminSuccess: (state, action) => {
      state.allBlogsList.data = state.allBlogsList.data.filter(
        (x) => x.id !== action.payload.id
      );
    },
  },
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  addBlogsByAdminSuccess,
  getBlogsByAdminSuccess,
  blogDescriptionSuccess,
  updateBlogByAdminSuccess,
  deleteBlogByAdminSuccess,
  clearBlogDetailSuccess,
} = slice.actions;

export const addBlogsByAdmin = (request, navigate) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.blogs.addBlogs}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        navigate("/blogs");
        toast.success(response.data.message);
        dispatch(addBlogsByAdminSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const getBlogsByAdmin = (request, setIsLoading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.blogs.getBlogs}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(getBlogsByAdminSuccess(result));
      } else {
        toast.error(result.message);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    })
    .catch(() => {});
};
export const blogDetails = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.blogs.blogDescription}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(blogDescriptionSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const updateBlogByAdmin = (request, navigate) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.blogs.updateBlog}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        navigate("/blogs");
        toast.success(response.data.message);
        dispatch(updateBlogByAdminSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const deleteBlogByAdmin = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.blogs.deleteBlog}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        toast.success(response.data.message);
        dispatch(deleteBlogByAdminSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const clearBlogDetail = () => async (dispatch) => {
  dispatch(clearBlogDetailSuccess(null));
};
