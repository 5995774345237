import React, { useEffect } from "react";
import OwnerHeader from "layout/dashboardLayout/header";
import { Icon } from "@iconify/react";
import { blogDetails } from "store/slice/blogsData";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
const BlogDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { descriptionOfBlog } = useSelector((state) => state.blogsData);

  useEffect(() => {
    if (id) {
      let request = {
        language: "en",
        blogId: id,
      };
      dispatch(blogDetails(request));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // console.log("descriptionOfBlog", descriptionOfBlog);

  return (
    <>
      <OwnerHeader
        title={"Blogs Details"}
        previousPage={"Blogs"}
        currentPage={"Blog Details"}
        previousPageUrl={"/blogs"}
      />
      <h3 className="ml-3 my-5" style={{ fontFamily: "Circular Std Medium" }}>
        {descriptionOfBlog?.addTags}
        <h6 className="mt-2">
          <small className="smallText">{descriptionOfBlog?.addTags}</small>
          &nbsp;&nbsp;
          <small className="d-inline d-md-inline">
            <Icon icon="mdi:dot" />
          </small>
          &nbsp;&nbsp;
          <small className="smallText">{descriptionOfBlog?.addTags}</small>
        </h6>
        <h6
          className=" mt-3"
          style={{ fontFamily: "Circular Std Book", color: "#8992A4" }}
        >
          {moment(descriptionOfBlog?.createdAt).format("Do MMM, YYYY")}
        </h6>
      </h3>
      <div className="col-md-12 col-sm-12">
        <img
          src={descriptionOfBlog?.blogThumbnail}
          width={"100%"}
          height={"400px"}
          style={{ objectFit: "cover", borderRadius: "1rem" }}
        />
        <p className="mt-3 blog-paragraph">{descriptionOfBlog?.writeBlogs}</p>
        {/* <p className="blog-paragraph">{descriptionOfBlog?.writeBlogs}</p>
        <p className="blog-paragraph">{descriptionOfBlog?.writeBlogs}</p> */}
      </div>
    </>
  );
};

export default BlogDetails;
