import Input from "components/input";
import OwnerHeader from "layout/dashboardLayout/header";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getTheListOfPolicy, setPolicyByAdmin } from "store/slice/policy";

const EditCancellationPolicy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const { id } = useParams();
  const { getPolicy } = useSelector((state) => state.policy);
  const [formData, setFormData] = useState({
    title: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (id) {
      let request = {
        policyType: 2,
        language: "en",
      };
      dispatch(getTheListOfPolicy(request));
    }
  }, [id]);
  useEffect(() => {
    if (getPolicy) {
      setFormData(getPolicy);
      setValue(getPolicy?.description);
    }
  }, [getPolicy]);

  const handleSubmit = () => {
    const { title } = formData;
    const description = value;
    if (title.trim().length==0 || description.trim().length==0) {
      toast.error("Please fill out all fields.");
      return;
    }
    if (!title || !description) {
      toast.error("Please fill out all fields.");
      return;
    }
    if (id) {
      const request = {
        language: "en",
        title: title,
        description: description, // Change value to description here
        policyType: 2,
      };

      dispatch(setPolicyByAdmin(request, navigate));
      setFormData({ title: "" });
      setValue("");
    } else {
      const request = {
        language: "en",
        title: title,
        description: description, // Change value to description here
        policyType: 2,
      };

      dispatch(setPolicyByAdmin(request, navigate));
      setFormData({ title: "" });
      setValue("");
    }
  };
  const isDescriptionBlank = !getPolicy?.description || getPolicy?.description.trim() === '';
  return (
    <>
      <OwnerHeader
        title={isDescriptionBlank ? " Add Cancellation Policy" : "Edit Cancellation Policy"}
        previousPage={"Cancellation Policy"}
        currentPage={isDescriptionBlank ? "Add Policy":"Edit Policy" }
        previousPageUrl={"/cancellation-policy"}
      />
      <div className="my-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div
              className="mt-3"
              style={{
                fontFamily: "Circular Std Book Italic",
                color: "#8992a4",
              }}
            >
              Create your Policy for rental and user
            </div>
            <div className="myCar-label mt-3">Main Title</div>
            <div className="form-group pt-3 ">
              <Input
                name="title"
                value={formData?.title}
                onChange={(val) => {
                  setFormData({ ...formData, title: val });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label">Description</div>
            <div className="form-group pt-3">
              <ReactQuill
                theme="snow"
                value={value}
                onChange={setValue}
                name="description"
              />
            </div>
          </div>

          <div className="d-grid gap-2 col-8" style={{ marginTop: "20px" }}>
            <button
              onClick={handleSubmit}
              className="btn btn-warning"
              type="button"
              style={{
                fontFamily: "Circular Std Black",
                width: "20rem",
                marginTop: "auto",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCancellationPolicy;
