import OwnerHeader from "layout/dashboardLayout/header";
import React, { useEffect, useRef, useState } from "react";
import { useResponsive } from "themes/useResponsive";
import profileImage from "../../../assets/img/GirlLogo.png";
import image from "../../../assets/img/paymentBg.png";
import { Icon } from "@iconify/react";
import UpdateRentalProfile from "components/dialog/updateRentalProfile";
import { getAllCarsByAdmin } from "store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CarBox from "./carBox";

const RentalDetails = () => {
  const screenType = useResponsive();
  const dispatch = useDispatch();
  const { allCarsList } = useSelector((state) => state.dashboard);
  const { id } = useParams();
  const [show, setShow] = useState(false);
  useEffect(() => {
    fetchCarList();
  }, []);

  const fetchCarList = () => {
    let request = {
      userId: id,
      page: 1,
      perPage: 10,
      language: "en",
    };
    dispatch(getAllCarsByAdmin(request));
  };
  return (
    <>
      <OwnerHeader
        title={"Rental Details"}
        previousPage={"Dashboard"}
        previousPageUrl={"/dashboard"}
        middlePage={"Total Rentals"}
        middlePageUrl={"/total-rentals"}
        currentPage={"Rental Details"}
      />

      <UpdateRentalProfile />
      <div className="pt-4" style={{ height: "auto" }}>
        <div
          className="card border-0 "
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            width: "100%",
            height: screenType != "desktop" ? "auto" : "150px",
            marginTop: "54px",
          }}
        >
          <div className="main-divv d-flex justify-content-between align-items-center">
            <div
              className="second-image"
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "1px",
                width: "150px",
                height: "150px",
              }}
            >
              <img
                src={
                  allCarsList?.userdata?.profileImagePath
                    ? allCarsList?.userdata?.profileImagePath
                    : profileImage
                }
                alt="Logo-Image"
                className="rounded-circle"
                style={{
                  marginLeft: "1rem",
                  marginBottom: "-2rem",
                  width: "90px",
                  height:"90px",
                  marginTop: screenType != "desktop" ? "-16px" : "-12px",
                  objectFit: "cover",
                }}
              />
              <div className="client-image-div pt-2">
                <div className="client-name-number">
                  <div className="d-flex">
                    <h4
                      className="client-name"
                      style={{
                        fontFamily: "circular std Bold",
                        marginLeft: "15px",
                      }}
                    >
                      {allCarsList?.userdata?.firstName}
                    </h4>
                    <h4
                      className="client-name"
                      style={{
                        fontFamily: "circular std Bold",
                      }}
                    >
                      &nbsp;{allCarsList?.userdata?.lastName}
                    </h4>
                  </div>
                  <div
                    style={{
                      fontFamily: "circular std Book",
                      marginLeft: "15px",
                      color: "#5c6064",
                    }}
                  >
                    {allCarsList?.userdata?.email}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mr-3">
              <div
                type="button"
                data-toggle="modal"
                data-target="#updateRentalProfile"
                onClick={() => setShow(!show)}
                style={{
                  background: "#FFFFFF",
                  borderRadius: 35,
                  height: 40,
                  width: 40,
                  position: "absolute",
                  top: 53,
                  right: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon="gravity-ui:pencil-to-line"
                  color="orange"
                  height={20}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-8 text-muted fs-2 carOwner-tab-title mt-5">
          Cars
        </div>
        <div className="row">
          {allCarsList?.rentalcars?.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12">
                <CarBox key={index}
              data={item}/>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RentalDetails;
