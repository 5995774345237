import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { AxiosInterceptor } from "./services";
import { Toaster } from "react-hot-toast";
import Router from "./routes";
function App() {
  // const CloseButton = ({ closeToast }) => (
  //   <Button size="small" onClick={closeToast} style={{ color: "white" }}>
  //     Ok
  //   </Button>
  // );

  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <AxiosInterceptor>
            <Router />
          </AxiosInterceptor>
        </BrowserRouter>
      </HelmetProvider>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
        }}
      />
    </>
  );
}

export default App;
