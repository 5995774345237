import React, { useEffect, useState } from "react";
import Input from "components/input";
import OwnerHeader from "layout/dashboardLayout/header";
import {
  addCouponsByAdmin,
  getCouponCodeById,
  updateCouponsByAdmin,
} from "store/slice/coupons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SelectInput from "components/select";
import toast from "react-hot-toast";

const CreateCoupons = () => {
  const dispatch = useDispatch();
  const { couponDetail } = useSelector((state) => state.coupons);
  const [isUpdate, setIsUpdate] = useState(false); // State to track if it's an update
  const [formData, setFormData] = useState({
    couponTitle: "",
    couponCode: "",
    expiryDate: "",
    discountType: "",
    discountAmount: "",
  });
  const { id } = useParams();
  const [formError, setFormError] = useState({});

  useEffect(() => {
    if (id) {
      let request = {
        id: id,
        language: "en",
      };
      dispatch(getCouponCodeById(request));
      setIsUpdate(true); // Set isUpdate to true when id exists
    }
  }, [id]);
  useEffect(() => {
    if (couponDetail) {
      setFormData(couponDetail);
    }
  }, [couponDetail]);
  const couponsTypeList = ["flat", "percentage"];
  const navigate = useNavigate();
  const handleSubmit = () => {
    const {
      couponTitle,
      couponCode,
      discountType,
      discountAmount,
      expiryDate,
    } = formData;

    if (!couponTitle) {
      setFormError((formError) => ({
        ...formError,
        couponTitleErr: "Please add title here",
      }));
      return;
    }
    if (couponTitle.trim().length == " ") {
      setFormError((formError) => ({
        ...formError,
        couponTitleErr: "Please add title here",
      }));
      return;
    }
    if (!couponCode) {
      setFormError((formError) => ({
        ...formError,
        couponCodeErr: "Please add Coupon Code",
      }));
      return;
    }
    if (couponCode.trim().length == " ") {
      setFormError((formError) => ({
        ...formError,
        couponCodeErr: "Please add Coupon Code",
      }));
      return;
    }
    if (!discountType) {
      setFormError((formError) => ({
        ...formError,
        discountTypeErr: "Please add discount type",
      }));
      return;
    }
    if (!discountAmount) {
      setFormError((formError) => ({
        ...formError,
        discountAmountErr: "Please add discount amount",
      }));
      return;
    }
    if (!expiryDate) {
      setFormError((formError) => ({
        ...formError,
        expiryDateErr: "Please add expiry date",
      }));
      return;
    }
    if (id) {
      var request = {
        couponId: id,
        couponTitle: formData?.couponTitle,
        couponCode: formData?.couponCode,
        discountType: formData?.discountType,
        discountAmount: formData?.discountAmount,
        expiryDate: formData?.expiryDate,
        // couponStatus: "string",
        language: "en",
      };
      dispatch(updateCouponsByAdmin(request, navigate));
    } else {
      var request = {
        couponTitle: formData?.couponTitle,
        couponCode: formData?.couponCode,
        discountType: formData?.discountType,
        discountAmount: formData?.discountAmount,
        expiryDate: formData?.expiryDate,
        // couponStatus: "string",
        language: "en",
      };
      dispatch(addCouponsByAdmin(request, navigate));
    }
  };

  return (
    <>
      <OwnerHeader
        title={id ? "Update Coupon" : "Create Coupons"}
        previousPage={"Coupons"}
        currentPage={id ? "Update Coupon" : "Create Coupons"}
        previousPageUrl={"/coupons"}
      />
      <div className="my-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div
              className="mt-3"
              style={{
                fontFamily: "Circular Std Book Italic",
                color: "#8992a4",
              }}
            >
              Create your coupon for both users
            </div>
            <div className="myCar-label mt-3">Main Title</div>
            <div className="form-group pt-3">
              <Input
                name="couponTitle"
                value={formData?.couponTitle}
                onChange={(val) => {
                  setFormData({ ...formData, couponTitle: val });
                  setFormError((formError) => ({
                    ...formError,
                    couponTitleErr: "",
                  }));
                }}
              />
              {formError.couponTitleErr && (
                <span className="text-danger">{formError?.couponTitleErr}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label mt-3">Coupon Code</div>
            <div className="form-group pt-3">
              <Input
                name="couponCode"
                value={formData?.couponCode}
                maxLength={10}
                onChange={(val) => {
                  setFormData({ ...formData, couponCode: val });
                  setFormError((formError) => ({
                    ...formError,
                    couponCodeErr: "",
                  }));
                }}
              />

              {formError.couponCodeErr && (
                <span className="text-danger">{formError?.couponCodeErr}</span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label mt-3">Expiry Date</div>
            <div className="form-group pt-3">
              <Input
                type="date"
                name="expiryDate"
                value={formData?.expiryDate}
                onChange={(val) => {
                  setFormData({ ...formData, expiryDate: val });
                  setFormError((formError) => ({
                    ...formError,
                    expiryDateErr: "",
                  }));
                }}
              />
              {formError.expiryDateErr && (
                <span className="text-danger">{formError?.expiryDateErr}</span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label mt-3">Coupon Discount Type</div>
            <div className="form-group pt-3">
  <SelectInput
    name="discountType"
    value={formData?.discountType}
    onChange={(e) => {
      const newDiscountType = e.target.value;
      setFormData({
        ...formData,
        discountType: newDiscountType,
        discountAmount: "" // Clear discountAmount when discountType changes
      });
      setFormError((formError) => ({
        ...formError,
        discountTypeErr: "",
      }));
    }}
    options={couponsTypeList?.map((item, index) => (
      <option key={index} value={item}>
        {item}
      </option>
    ))}
    Placeholder={"Please select Discount Type"}
  />

  {formError.discountTypeErr && (
    <span className="text-danger">
      {formError?.discountTypeErr}
    </span>
  )}
</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label mt-3">Discount Amount</div>
            <div className="form-group pt-3">
            <div className="input-with-prefix">
  <Input
    name="discountAmount"
    value={formData?.discountAmount}
    maxLength={formData?.discountType === 'percentage' && 3} 
    onChange={(e) => {
      const inputVal = e;
      const numeric = /^[0-9\b]+$/;

      if (inputVal === "" || numeric.test(inputVal)) {
        if(formData?.discountType === 'percentage'){
          if(inputVal > 100){
            toast.error("Percent should be less than 100")
          }
        }
       
          setFormData({ ...formData, discountAmount: inputVal });
          setFormError((formError) => ({
            ...formError,
            discountAmountErr: "",
          }));
        
      } else {
        setFormData({ ...formData, discountAmount: "" });
        setFormError((formError) => ({
          ...formError,
          discountAmountErr: "Discount amount must be numeric",
        }));
      }
    }}
  />
</div>
              {formError.discountAmountErr && (
                <span className="text-danger">
                  {formError?.discountAmountErr}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-grid gap-2 col-8">
        <button
          className="btn btn-warning"
          type="submit"
          onClick={handleSubmit}
          style={{
            fontFamily: "Circular Std Black",
            width: "20rem",
            marginTop: "20px",
          }}
        >
          {isUpdate ? "Update" : "Create"}
        </button>
      </div>
    </>
  );
};

export default CreateCoupons;
