import { createSlice } from "@reduxjs/toolkit";
import { RENTEDWHEELSAPI } from "../../services/apiConstant";
import toast from "react-hot-toast";
import api from "../../services";
import secureLocalStorage from "react-secure-storage";
const slice = createSlice({
  name: "auth",
  initialState: {
    userProfileData: null,
    message: "",
    isLoading: false,
    emailVerifiedData: null,
    isOpenSideBar: false,
  },
  reducers: {
    apiFetching: (state) => {
      state.isLoading = true;
    },
    getUserProfileSuccess: (state, action) => {
      state.userProfileData = action.payload;
    },
    updateAdminProfileSuccess: (state, action) => {
      state.userProfileData = action.payload;
    },
    handleSideBarSuccess: (state, action) => {
      state.isOpenSideBar = action.payload;
    },
  },
});

export default slice.reducer;

/**********************ACTIONS************************************************ */
const {
  getUserProfileSuccess,
  updateAdminProfileSuccess,
  handleSideBarSuccess,
} = slice.actions;

export const adminLogIn =
  (requestParams, setIsLoading, navigate) => async () => {
    api
      .post(`${RENTEDWHEELSAPI.auth.adminLogin}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status === 1) {
          secureLocalStorage.setItem(
            process.env.REACT_APP_TOKEN_STORAGE_KEY,
            result.token
          );
          secureLocalStorage.setItem(
            "authenticated",
            JSON.stringify(result.token)
          );
          toast.success(result.message);
          navigate("/dashboard");
          setIsLoading(false);
          window.location.reload();
        } else {
          setIsLoading(false);
          toast.error(result.message);
        }
      })
      .catch(() => {});
  };

//  get otp for reset password
export const getOtpForResetPass =
  (requestParams, setForgotData) => async () => {
    api
      .post(`${RENTEDWHEELSAPI.auth.getForgotOtp}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status === 1) {
          if (setForgotData) {
            setForgotData((forgotData) => ({
              ...forgotData,
              showOtpScreen: true,
            }));
          }
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => {});
  };

//   verify otp for reset password
export const verfiyOtpForResetPass =
  (requestParams, setForgotData) => async () => {
    api
      .post(`${RENTEDWHEELSAPI.auth.verfiyForgotOtp}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status === 1) {
          if (setForgotData) {
            setForgotData((forgotData) => ({
              ...forgotData,
              showOtpScreen: false,
              otpVerified: true,
            }));
          }
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => {});
  };

// reset password
export const resetPassword =
  (requestParams, setForgotData, navigate) => async () => {
    api
      .post(`${RENTEDWHEELSAPI.auth.resetPassword}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status === 1) {
          setForgotData({});
          toast.success(result.message);
          navigate("/auth/login");
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => {});
  };

// change password
export const changePassword =
  (requestParams, setChangePassData, closeRef) => async () => {
    api
      .post(`${RENTEDWHEELSAPI.auth.changePassword}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status === 1) {
          setChangePassData((changePassData) => ({
            ...changePassData,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }));
          toast.success(result.message);
          closeRef.current.click();
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => {});
  };

// get user  profile
export const getUserProfile = () => async (dispatch) => {
  api
    .get(`${RENTEDWHEELSAPI.auth.getUserProfile}`)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(getUserProfileSuccess(result.userProfile));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

// update user  profile
export const updateAdminProfile = (request, closeRef) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.auth.updateAdminProfile}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(updateAdminProfileSuccess(result.data));
        toast.success(result.message);
        closeRef.current.click();
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

//  openClose side bar
export const handleSideBar = (data) => async (dispatch) => {
  dispatch(handleSideBarSuccess(data));
};
