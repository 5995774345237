import { createSlice } from "@reduxjs/toolkit";
import { RENTEDWHEELSAPI } from "../../services/apiConstant";
import api from "../../services";
import toast from "react-hot-toast";

const slice = createSlice({
  name: "dashboard",
  initialState: {
    totalCounts: [],
    totalRental: [],
    allUsers: [],
    totalCommission: [],
    statisticsList:[],
    // getpdf:null
    getpdf:[]
  },
  reducers: {
    dashboardTotalCountSuccess: (state, action) => {
      state.totalCounts = action.payload;
    },
    totalRentalCarListSuccess: (state, action) => {
      state.totalRental = action.payload;
    },
    totalUsersSuccess: (state, action) => {
      state.allUsers = action.payload;
    },
    TotalCommissionPaidSuccess: (state, action) => {
      state.totalCommission = action.payload;
    },
    addRentalUserByAdminSuccess: (state, action) => {
      state.addRentalUser = action.payload;
    },
    updateRentedUserProfileSuccess: (state, action) => {
      state.rentaluserProfile = action.payload;
      // let userId= action.payload._id
      state.allCarsList.userdata = state.rentaluserProfile

 
    },
    getAllCarsByAdminSuccess: (state, action) => {
      state.allCarsList = action.payload;
      
    },
    getTransactionListSuccess: (state, action) => {
      state.transactionList = action.payload;
    },
    getTheGraphSuccess: (state, action) => {
      state.statisticsList = action.payload;
    },
    generateThePdfSuccess: (state, action) => {
      state.getpdf = action.payload;
    },
    clearStatisticsList: (state) => {
      state.statisticsList = []
    },
  },
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  dashboardTotalCountSuccess,
  totalRentalCarListSuccess,
  totalUsersSuccess,
  TotalCommissionPaidSuccess,
  addRentalUserByAdminSuccess,
  updateRentedUserProfileSuccess,
  getAllCarsByAdminSuccess,
  getTransactionListSuccess,
  getTheGraphSuccess,
  generateThePdfSuccess,
  clearStatisticsList
} = slice.actions;

export const clearStateData = () => async (dispatch) => {
  dispatch(clearStatisticsList());
};

export const dashboardTotalCounts = (request) => async (dispatch) => {
  api
    .get(`${RENTEDWHEELSAPI.dashboard.totalCount}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(dashboardTotalCountSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const totalRentalCarList =
  (request, setIsLoading) => async (dispatch) => {
    api
      .post(`${RENTEDWHEELSAPI.dashboard.totalRentalCarList}`, request)
      .then((response) => {
        let result = response.data;
        console.log("response")
        if (result.status === 1) {
          dispatch(totalRentalCarListSuccess(result.data));
     

        } else {
          toast.error(result.message);
        }
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch(() => {});
  };

export const totalUsers = (request, setIsLoading, handleDataCheck) => async (dispatch) => {
  try {
    setIsLoading(true); // Start loading

    const response = await api.post(`${RENTEDWHEELSAPI.dashboard.totalUsers}`, request);
    const result = response.data;

    if (result.status === 1) {
      dispatch(totalUsersSuccess(result)); // Dispatch success action with data
    } else {
      toast.error(result.message); // Display error message if status is not 1
    }

    if (handleDataCheck) {
      handleDataCheck(result); // Callback to check data presence
    }
  } catch (error) {
    console.error("Error fetching total users:", error);
    toast.error("Failed to fetch total users."); // Handle API call error
  } finally {
    setIsLoading(false); // Always stop loading, regardless of success or failure
  }
};
export const TotalCommissionPaid =
  (request, setIsLoading) => async (dispatch) => {
    api
      .post(`${RENTEDWHEELSAPI.dashboard.TotalCommissionPaid}`, request)
      .then((response) => {
        let result = response.data;
        if (result.status === 1) {
          dispatch(TotalCommissionPaidSuccess(result.data));
        } else {
          toast.error(result.message);
        }
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch(() => {});
  };
export const addRentalUserByAdmin = (request, closeRef,fetchRentalList) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.dashboard.addRentalUserByAdmin}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(addRentalUserByAdminSuccess(result.data));
        toast.success(result.message);
        closeRef.current.click();
        
        fetchRentalList()
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const updateRentedUserProfile =
  (request, closeRef, setUpdateRentalProfile) => async (dispatch) => {
    api
      .post(
        `${RENTEDWHEELSAPI.dashboard.updateRentedUserProfile}?userId=${request?.userId}`,
        request
      )
      .then((response) => {
        let result = response.data;
        if (result.status === 1) {
          dispatch(updateRentedUserProfileSuccess(result.data));
          setUpdateRentalProfile({});
          toast.success(result.message);
          closeRef.current.click();
        } else {
          toast.error(result.message);
        }
      })
      .catch(() => {});
  };
export const getAllCarsByAdmin = (request) => async (dispatch) => {
  api
    .post(
      `${RENTEDWHEELSAPI.dashboard.getAllCarsByAdmin}?userId=${request?.userId}&language=${request?.language}`
    )
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(getAllCarsByAdminSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const getTransactionList = (request, setIsLoading, setIsListloading) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.dashboard.getTransactionList}`, request)
    .then((response) => {
      let result = response.data;
      console.log("response.data",response.data);
      if (result.status === 1) {
        dispatch(getTransactionListSuccess(result));
      } else {
        toast.error(result.message);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
      setIsListloading(false);
    })
    .catch(() => {});
};
export const getTheGraph = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.dashboard.getTheGraph}`, request)
    .then((response) => {
      let result = response.data;
      console.log("response.data",response.data);
      if (result.status === 1) {
        dispatch(getTheGraphSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const generateThePdf = (request) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.dashboard.generateThePdf}`, request)
    .then((response) => {
      let result = response.data;
      console.log("response.data",response.data);
      if (result.status === 1) {
        dispatch(generateThePdfSuccess(result));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
