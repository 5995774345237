import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "./slice/auth";
import blogsData from "./slice/blogsData";
import coupons from "./slice/coupons";
import policy from "./slice/policy";
import notification from "./slice/notification";
import dashboard from "./slice/dashboard";

const rootReducer = combineReducers({
  auth,
  blogsData,
  coupons,
  policy,
  notification,
  dashboard,
});
const store = configureStore({
  reducer: rootReducer,
});
export default store;
