import React from 'react';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../store/slice/auth';

export default function ResetPassword(props) {
  const { forgotData, setForgotData } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleReset = () => {
    if (!forgotData.password) {
      setForgotData((forgotData) => ({
        ...forgotData,
        passwordErr: 'Please enter password'
      }));
      return;
    }
    if (forgotData.password.length <= 7) {
      setForgotData((forgotData) => ({
        ...forgotData,
        passwordErr: 'Password must be  8 characters '
      }));
      return;
    }
    if (!forgotData.confirmPassword) {
      setForgotData((forgotData) => ({
        ...forgotData,
        confirmPasswordErr: 'Please confirm password'
      }));
      return;
    }
    if (forgotData.confirmPassword.length <= 7) {
      setForgotData((forgotData) => ({
        ...forgotData,
        confirmPasswordErr: 'Password must be  8 characters '
      }));
      return;
    }
    if (forgotData.confirmPassword !== forgotData.password) {
      setForgotData((forgotData) => ({
        ...forgotData,
        confirmPasswordErr: 'Password not match '
      }));
      return;
    }
    let request = {
      email: forgotData?.email,
      newPassword: forgotData?.password
    };
    dispatch(resetPassword(request, setForgotData, navigate));
  };
  return (
    <>
      <div className="pb-2">
        <h3 className="authHeading">OTP is Verified </h3>
        <h3 className="authHeading"> Reset Password.</h3>
      </div>
      <p className="mb-4 text-muted pr-5" style={{ fontFamily: 'Circular Std Book', fontSize: 19 }}>
        Please reset new strong password
      </p>
      <form action="#" method="post" className="pt-5">
        <div className="form-group first ">
          <Input
            onChange={(val) => {
              setForgotData((forgotData) => ({ ...forgotData, password: val, passwordErr: '' }));
            }}
            type={!forgotData?.showPass ? 'password' : 'text'}
            endIcon={forgotData?.showPass ? 'formkit:eye' : 'humbleicons:eye-off'}
            passwordClick={() =>
              setForgotData((forgotData) => ({
                ...forgotData,
                showPass: !forgotData?.showPass
              }))
            }
            value={forgotData?.password}
            formError={forgotData?.passwordErr}
            placeholder={'New Password'}
            icon={'circum:lock'}
          />
        </div>
        <div className="form-group first ">
          <Input
            onChange={(val) => {
              setForgotData((forgotData) => ({
                ...forgotData,
                confirmPassword: val,
                confirmPasswordErr: ''
              }));
            }}
            formError={forgotData?.confirmPasswordErr}
            value={forgotData?.confirmPassword}
            type={!forgotData?.showConfirmPass ? 'password' : 'text'}
            endIcon={forgotData?.showConfirmPass ? 'formkit:eye' : 'humbleicons:eye-off'}
            passwordClick={() =>
              setForgotData((forgotData) => ({
                ...forgotData,
                showConfirmPass: !forgotData?.showConfirmPass
              }))
            }
            placeholder={'Re-enter New Password'}
            icon={'circum:lock'}
          />
        </div>
      </form>
      <div className="mt-5">
        <Button onClick={handleReset} title={'Reset Now'} />
      </div>
      <div className="d-flex mt-5 mb-5 align-items-center">
        <div className="text-left text-muted">
          <div
            className="d-inline highlight"
            style={{ fontSize: 20 }}
            onClick={() => navigate('/')}>
            Back to Homepage
          </div>
        </div>
      </div>
    </>
  );
}
