import { Icon } from "@iconify/react";
import React, { useRef, useState } from "react";
import profile from "../../assets/img/noProfile.jpg";
import { getUserProfile, handleSideBar } from "../../store/slice/auth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loginData } from "../../components/constant";
import { Overlay } from "react-bootstrap";
import { useResponsive } from "../../themes/useResponsive";
import ChangePassword from "../../components/dialog/changePassword";
import UpdateProfile from "../../components/dialog/updateAdminProfile";
// import Autocomplete from '../../components/autocomplete';
import notificationLogo from "assets/img/owner-icons/Notification.png";

import { Link } from "react-router-dom";
import LogoutDialog from "components/dialog/logout";
export default function OwnerHeader(props) {
  const {
    title,
    previousPage,
    previousPageUrl,
    currentPage,
    middlePage,
    middlePageUrl,
  } = props;
  const dispatch = useDispatch();
  const { screenType } = useResponsive();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [show, setShow] = useState(false);
  const target = useRef();
  const { userProfileData } = useSelector((state) => state.auth);

  useEffect(() => {
    if (loginData) {
      dispatch(getUserProfile());
    }
  }, []);
  const handleLogout = () => {
    console.log("ff");
    setShowLogoutDialog(true);
  };

  return (
    <>
      <LogoutDialog show={showLogoutDialog} setShow={setShowLogoutDialog} />
      <ChangePassword />
      <UpdateProfile userProfileData={userProfileData} />
      <nav
        className="navbar "
        style={{
          background: "white",
          margin: "-1.8rem",
          padding: 15,
          paddingTop: "2rem",
          
        }}
      >
        {screenType === "MOBILE" ? (
         <>
          <Icon
            icon="akar-icons:three-line-horizontal"
            height={30}
            onClick={() => {
              dispatch(handleSideBar(true));
            }}
          />
             <div
             className="col-11"

            style={{
      
              fontFamily: "Circular Std Bold",
              color: "#132649",
              fontSize: 26,
              // paddingLeft: "2rem",
            }}
          >
            {title}
          </div>
          </>
        ) : (
          <div
            style={{
              fontFamily: "Circular Std Bold",
              color: "#132649",
              fontSize: 26,
              // paddingLeft: "2rem",
            }}
          >
            {title}
          </div>
        )}
        {previousPage && (
          <div style={{ fontFamily: "Circular Std Medium", cursor: "pointer" ,
          marginLeft: screenType === "MOBILE" ? "": "-8rem"}}>
            <Link
              to={previousPageUrl}
              style={{ color: "#63575778", textDecoration: "none" }}
            >
              {previousPage}
            </Link>
            {middlePage && (
              <>
                &nbsp;/&nbsp;
                <Link
                  to={middlePageUrl}
                  style={{ color: "#63575778", textDecoration: "none" }}
                >
                  {middlePage}
                </Link>
              </>
            )}
            &nbsp;/&nbsp;<span>{currentPage}</span>
          </div>
        )}

        <div className="d-flex">
          {/* <form className="form-search form-inline rounded-3 shadow-sm">
            <div className="px-4">
              <Icon icon={"ri:search-line"} height={30} color="#a0a6b1" />
              <input
                className="payment-input"
                placeholder="search"
                // style={{
                //   borderBottom: "2px solid #ced1d7d9",
                //   padding: "",
                //   height: 35,
                // }}
                style={{ width: "140px" }}
                // onChange={(e) => {
                //   transactionList(e.target.value);
                //   setFilterData((filterData) => ({
                //     ...filterData,
                //     search: e.target.value,
                //   }));
                // }}
              />
            </div>
          </form> */}
          {/* <Link
            className="userNav nav-link"
            to="/car-owner/notification"
            style={{ paddingTop: "0.4rem", paddingRight: "2rem" }}
          >
            <img src={notificationLogo} width={25} />
          </Link> */}

          <a
            className="userNav nav-link"
            ref={target}
            onClick={() => setShow(!show)}
            style={{ height: 40, width: 40 }}
          >
            <img
              src={
                userProfileData?.profileImagePath
                  ? userProfileData?.profileImagePath
                  : profile
              }
              className="rounded-circle shadow-4"
              style={{ width: "100%", height: "100%" }}
              alt="Avatar"
            />
          </a>
        </div>
        <Overlay target={target.current} show={show} placement="bottom">
          <div
            className="px-3 py-2"
            // {...props}
            style={{
              position: "absolute",
              backgroundColor: "white",
              marginTop: "1rem",
              color: "black",
              borderRadius: 10,
              width: screenType === "MOBILE" ? 230 : 260,
              //   ...props.style
            }}
          >
            <div
              style={{
                background: "#E9F8F6",
                padding: "1rem",
                borderRadius: 10,
                position: "relative",
                marginTop: "2.5rem",
              }}
            >
              <div
                style={{
                  border: "4px solid white",
                  width: "fit-content",
                  borderRadius: 35,
                  position: "absolute",
                  top: -40,
                }}
              >
                <img
                  src={
                    userProfileData?.profileImagePath
                      ? userProfileData?.profileImagePath
                      : profile
                  }
                  className="rounded-circle shadow-4"
                  style={{ width: 60, height: 60 }}
                  alt="Avatar"
                />
              </div>
              <div
                type="button"
                data-toggle="modal"
                data-target="#updateProfile"
                onClick={() => setShow(!show)}
                style={{
                  background: "#6CD2C2",
                  borderRadius: 35,
                  height: 40,
                  width: 40,
                  position: "absolute",
                  top: 10,
                  right: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon="gravity-ui:pencil-to-line"
                  color="white"
                  height={20}
                />
              </div>
              <div
                style={{
                  color: "#132649",
                  fontWeight: 600,
                  paddingTop: "1rem",
                  whiteSpace: "nowrap",
                  width: "80%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userProfileData?.firstName} {userProfileData?.lastName}
              </div>
              <div
                className="text-muted"
                style={{
                  fontSize: 15,
                  whiteSpace: "nowrap",
                  width: "90%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userProfileData?.email}
              </div>
              <div className="text-muted" style={{ fontSize: 15 }}>
                {`+${userProfileData?.countryCode} ${userProfileData?.phoneNumber}`}
              </div>
            </div>
            <div
              className="w-100 d-flex justify-content-between py-3"
              style={{ cursor: "pointer" }}
            >
              <div
                onClick={() => setShow(!show)}
                type="button"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                className="text-muted text-primary"
              >
                Change Password
              </div>
              <div>
                <Icon icon="grommet-icons:next" height={15} color="#757c83" />
              </div>
            </div>
            <hr />
            <div
              onClick={handleLogout}
              className="text-red text-center pb-2"
              style={{
                cursor: "pointer",
                marginTop: "-0.5rem",
                color: "#FF6767",
                fontWeight: 600,
              }}
            >
              Logout
            </div>
          </div>
        </Overlay>
      </nav>
    </>
  );
}
