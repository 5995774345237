import { createSlice } from "@reduxjs/toolkit";
import { RENTEDWHEELSAPI } from "../../services/apiConstant";
import api from "../../services";
import toast from "react-hot-toast";

const slice = createSlice({
  name: "coupons",
  initialState: {
    addCoupons: [],
    couponsList: [],
    couponDetail: [],
    // deletedCouponsList: [],
  },
  reducers: {
    addCouponsByAdminSuccess: (state, action) => {
      state.addCoupons = action.payload;
    },
    getcouponsListByAdminSuccess: (state, action) => {
      state.couponsList = action.payload;
    },
    addCouponsByAdminSuccess: (state, action) => {
      state.updateCoupons = action.payload;
    },
    deleteCouponByAdminSuccess: (state, action) => {
      // state.deletedCouponsList = action.payload;
      state.couponsList.data.formattedCoupons =
        state.couponsList.data.formattedCoupons.filter(
          (x) => x.id != action.payload.id
        );
      // state.couponsList[index] = action.payload;
    },
    getCouponCodeByIdSuccess: (state, action) => {
      state.couponDetail = action.payload;
    },
    clearCouponDetailSuccess: (state, action) => {
      state.couponDetail = action.payload;
    },
  },
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const {
  addCouponsByAdminSuccess,
  getcouponsListByAdminSuccess,
  deleteCouponByAdminSuccess,
  updateCouponsByAdminSuccess,
  getCouponCodeByIdSuccess,
  clearCouponDetailSuccess,
} = slice.actions;


export const addCouponsByAdmin = (request, navigate) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.coupons.addCoupon}`, request)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        navigate("/coupons");
        toast.success(response.data.message);
        dispatch(addCouponsByAdminSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const getcouponsListByAdmin =
  (request, setIsLoading) => async (dispatch) => {
    api
      .post(`${RENTEDWHEELSAPI.coupons.getcouponsList}`, request)
      .then((response) => {
        let result = response.data;
        if (result.status === 1) {
          dispatch(getcouponsListByAdminSuccess(result));
        } else {
          toast.error(result.message);
        }
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch(() => {});
  };
export const deleteCouponByAdmin = (request) => async (dispatch) => {
  api
    .post(
      `${RENTEDWHEELSAPI.coupons.deleteCoupon}?couponId=${request?.couponId}&language=${request?.language}`
    )
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        toast.success(response.data.message);
        dispatch(deleteCouponByAdminSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const updateCouponsByAdmin = (request, navigate) => async (dispatch) => {
  api
    .post(
      `${RENTEDWHEELSAPI.coupons.updateCouponsByAdmin}/?couponId=${request?.couponId}`,
      request
    )
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        navigate("/coupons");
        toast.success(response.data.message);
        dispatch(updateCouponsByAdminSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const getCouponCodeById = (request) => async (dispatch) => {
  api
    .get(
      `${RENTEDWHEELSAPI.coupons.getCouponCodeById}?couponId=${request?.id}&language=${request?.language}`
    )
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(getCouponCodeByIdSuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

export const clearCouponDetail = () => async (dispatch) => {
  dispatch(clearCouponDetailSuccess(null));
};
