import React, { useEffect, useRef, useState } from "react";
import Input from "components/input";
import SelectInput from "components/select";
import OwnerHeader from "layout/dashboardLayout/header";
import { Icon } from "@iconify/react";
import {
  addBlogsByAdmin,
  updateBlogByAdmin,
  blogDetails,
} from "store/slice/blogsData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const CreateBlogs = () => {
  const carImagesRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isUpdate, setIsUpdate] = useState(false);
  const { descriptionOfBlog, allBlogsList } = useSelector(
    (state) => state.blogsData
  );

  useEffect(() => {
    setIsUpdate(!!id); // Convert id to boolean
  }, [id]);

  const [formData, setFormData] = useState({
    mainTitle: "",
    tags: "",
    writeBlogs: "",
    selectedImage: null,
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, selectedImage: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (id) {
      let request = {
        language: "en",
        blogId: id,
      };
      dispatch(blogDetails(request));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (descriptionOfBlog) {
      setFormData({
        ...formData,
        mainTitle: descriptionOfBlog?.mainTitle ?? "",
        tags: descriptionOfBlog?.addTags ?? "",
        writeBlogs: descriptionOfBlog?.writeBlogs ?? "",
        selectedImage: descriptionOfBlog?.blogThumbnail ?? null,
        error: "",
      });
    }
  }, [descriptionOfBlog]);

  const [formError, setFormError] = useState({});

  const handleDeleteImage = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up
    setFormData({ ...formData, selectedImage: null });

    // Reset file input element value to allow re-selection of the same file
    if (carImagesRef.current) {
      carImagesRef.current.value = null;
    }
  };

  const handleSubmit = () => {
    const { mainTitle, tags, writeBlogs, selectedImage } = formData;

    if (mainTitle.trim().length == 0) {
      setFormError((formError) => ({
        ...formError,
        mainTitleErr: "Please add title here",
      }));
      return;
    }
    if (!mainTitle) {
      setFormError((formError) => ({
        ...formError,
        mainTitleErr: "Please add title here",
      }));
      return;
    }

    if (!tags) {
      setFormError((formError) => ({
        ...formError,
        tagsErr: "Please add tags here",
      }));
      return;
    }
if(writeBlogs.trim().length==0){
  setFormError((formError) => ({
    ...formError,
    writeBlogsErr: "Blog content is required",
  }));
  return;
}
    if (!writeBlogs) {
      setFormError((formError) => ({
        ...formError,
        writeBlogsErr: "Blog content is required",
      }));
      return;
    }

    if (!selectedImage) {
      setFormError((formError) => ({
        ...formError,
        selectedImageErr: "Please add minimum one image",
      }));
      return;
    }

    // Clear form data on successful submission
    setFormData({
      mainTitle: "",
      tags: "",
      writeBlogs: "",
      selectedImage: null,
      error: "",
    });

    // Prepare request object based on whether it's an update or create action
    const request = {
      language: "en",
      mainTitle,
      addTags: tags,
      writeBlogs,
      blogThumbnail: selectedImage,
      ...(id && { blogId: id }),
    };

    // Dispatch action based on whether it's an update or create action
    if (id) {
      dispatch(updateBlogByAdmin(request, navigate));
    } else {
      dispatch(addBlogsByAdmin(request, navigate));
    }
  };

  return (
    <>
      <OwnerHeader
        title={id ? "Update Blog" : "Create Blog"}
        previousPage={"Blogs"}
        currentPage={id ? "Update Blog" : "Create Blog"}
        previousPageUrl={"/blog-details"}
      />

      <div className="my-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label">Main Title</div>
            <div className="form-group pt-3">
              <Input
                name="mainTitle"
                value={formData.mainTitle}
                onChange={(val) => {
                  setFormData({ ...formData, mainTitle: val });
                  setFormError((formError) => ({
                    ...formError,
                    mainTitleErr: "",
                  }));
                }}
              />
              {formError.mainTitleErr && (
                <span className="text-danger">{formError.mainTitleErr}</span>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label">Add Tags</div>
            <div className="form-group pt-3">
              <SelectInput
                name="tags"
                value={formData?.tags}
                onChange={(event) => {
                  setFormData({ ...formData, tags: event.target.value });
                  setFormError((formError) => ({
                    ...formError,
                    tagsErr: "",
                  }));
                }}
                options={allBlogsList?.data?.map((item) => (
                  <option key={item.id} value={item?.addTags}>
                    {item?.addTags}
                  </option>
                ))}
                Placeholder="Select Tags"
              />
              {formError.tagsErr && (
                <span className="text-danger">{formError?.tagsErr}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label">Write Blog</div>
            <div className="form-group pt-3">
              <textarea
                placeholder=""
                className="form-control"
                name="writeBlogs"
                value={formData.writeBlogs}
                onChange={(e) => {
                  setFormData({ ...formData, writeBlogs: e.target.value });
                  setFormError((formError) => ({
                    ...formError,
                    writeBlogsErr: "",
                  }));
                }}
                id="exampleFormControlTextarea1"
                rows="13"
              ></textarea>
              {formError.writeBlogsErr && (
                <span className="text-danger">{formError.writeBlogsErr}</span>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label">Blog Thumbnail</div>
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="form-group pt-3">
                <div className="form-group">
                  <div
                    onClick={() => {
                      if (carImagesRef.current) {
                        carImagesRef.current.click();
                      }
                    }}
                    className="myCar-addImageBoxBlog"
                  >
                    {formData.selectedImage ? (
                      <div className="position-relative">
                        <img
                          src={formData.selectedImage}
                          alt="Selected"
                          className="img-fluid rounded"
                          style={{
                            maxHeight: "300px",
                            width: "auto",
                            cursor: "pointer",
                          }}
                        />
                        <Icon
                          icon="oui:cross-in-circle-empty"
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            cursor: "pointer",
                            color: "red",
                          }}
                          onClick={handleDeleteImage}
                        />
                      </div>
                    ) : (
                      <div className="text-center">
                        <Icon
                          style={{ cursor: "pointer" }}
                          className="ml-3"
                          icon={"pepicons-pop:plus-circle-filled"}
                          color="#FFBB51"
                          height={45}
                        />
                        <div className="circularMedium pt-1">Add Image</div>
                      </div>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={carImagesRef}
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                </div>
                {formError.selectedImageErr && (
                  <span className="text-danger">
                    {formError.selectedImageErr}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            className="d-grid gap-2 col-8"
            style={{
              marginTop: "20px",
            }}
          >
            <button
              className="btn btn-warning"
              type="button"
              onClick={handleSubmit}
              style={{
                fontFamily: "Circular Std Black",
                width: "20rem",
                marginTop: "6rem",
              }}
            >
              {isUpdate ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBlogs;
