import React, { useEffect, useRef, useState } from "react";
import { useResponsive } from "themes/useResponsive";
import "react-calendar/dist/Calendar.css";
import { DateRange } from "react-date-range";
import { generateThePdf } from "store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const CalendarDialog = () => {
  const closeRef = useRef();
  const dispatch = useDispatch();
  const { screenType } = useResponsive();
  const { getpdf } = useSelector((state) => state.dashboard);
  console.log("getpdf", getpdf);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [downloadClicked, setDownloadClicked] = useState(false); // State to track if download button is clicked

  useEffect(() => {
    if (downloadClicked) {
      // Only trigger PDF generation when download button is clicked
      getAllDetails(
        state[0]?.startDate ? moment(state[0].startDate).format("MM/DD/YYYY") : "",
        state[0]?.endDate ? moment(state[0].endDate).format("MM/DD/YYYY") : ""
      );
    }
  }, [downloadClicked, state]);

  useEffect(() => {
    if (getpdf?.path && downloadClicked) {
      // Only trigger download when PDF path is available and download button was clicked
      downloadPdf(getpdf.path);
    }
  }, [getpdf, downloadClicked]);

  const getAllDetails = (startDate, endDate) => {
    let request = {
      language: "en",
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
    };
    dispatch(generateThePdf(request));
  };

  const handleDownloadCsv = () => {
    setDownloadClicked(true); // Set state to indicate that download button is clicked
  };

  const downloadPdf = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.pdf'); // Customize the file name if needed
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div
        className="modal fade bd-example-modal-lg"
        id="exampleModalCalendar"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div
            className={`modal-content py-2 ${
              screenType != "desktop" ? "" : "px-4"
            } `}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Sales Report
              </h5>
              <div className="d-flex justify-content-between gap-2">
                <button 
                  type="button" 
                  className="smallBtn" 
                  style={{ width: 120 }} 
                  onClick={handleDownloadCsv}
                >
                  Download Csv
                </button>
                <button
                  type="button"
                  ref={closeRef}
                  className="smallBtn"
                  style={{ width: 100 }}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row ">
                <div className="d-flex justify-content-center">
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarDialog;
