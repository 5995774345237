import Input from "components/input";
import OwnerHeader from "layout/dashboardLayout/header";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { setPolicyByAdmin, getTheListOfPolicy } from "store/slice/policy";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const EditPrivacyPolicy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getPolicy } = useSelector((state) => state.policy);
  const [value, setValue] = useState("");
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (id) {
      let request = {
        policyType: 1,
        language: "en",
        
      };
      dispatch(getTheListOfPolicy(request));
    }
  }, [id]);
  useEffect(() => {
    if (getPolicy) {
      setFormData(getPolicy);
      setValue(getPolicy?.description);
    }
  }, [getPolicy]);

  const isDescriptionBlank = !getPolicy?.description || getPolicy?.description.trim() === '';


  const handleSubmit = () => {
    const { title } = formData;
    const description = value;

    if (!title || !description) {
      toast.error("Please fill out all fields.");
      return;
    }
    if (id) {
      const request = {
        language: "en",
        title: title,
        description: description, // Change value to description here
        policyType: 1,
      };

      dispatch(setPolicyByAdmin(request, navigate));
      setFormData({ title: "" });
      setValue(""); // Clearing the ReactQuill editor
      toast.success("Saved");
    } else {
      const request = {
        language: "en",
        title: title,
        description: description, // Change value to description here
        policyType: 1,
      };

      dispatch(setPolicyByAdmin(request, navigate));
      setFormData({ title: "" });
      setValue(""); // Clearing the ReactQuill editor
     
    }
  };

  return (
    <>
      <OwnerHeader
        // title={" Edit Privacy Policy"}
        title={isDescriptionBlank ? " Add Privacy Policy" : "Edit Privacy Policy"}
        previousPage={"Privacy Policy"}
        currentPage={isDescriptionBlank ? "Add Policy":"Edit Policy"}
        previousPageUrl={"/privacy-policy"}
      />
      <div className="my-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div
              className="mt-3"
              style={{
                fontFamily: "Circular Std Book Italic",
                color: "#8992a4",
              }}
            >
              Create your Policy for rental and user
            </div>
            <div className="myCar-label mt-3">Main Title</div>
            <div className="form-group pt-3 ">
              <Input
                name="title"
                value={formData?.title}
                onChange={(val) => {
                  setFormData({ ...formData, title: val });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="myCar-label">Description</div>
            <div className="form-group pt-3">
              <ReactQuill
                theme="snow"
                value={value}
                onChange={setValue}
                name="description"
              />
            </div>
          </div>

          <div className="d-grid gap-2 col-8" style={{ marginTop: "20px" }}>
            <button
              onClick={handleSubmit}
              className="btn btn-warning"
              type="button"
              style={{
                fontFamily: "Circular Std Black",
                width: "20rem",
                marginTop: "auto",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPrivacyPolicy;

// import Input from "components/input";
// import OwnerHeader from "layout/dashboardLayout/header";
// import React, { useEffect, useState } from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import { setPolicyByAdmin } from "store/slice/policy";
// import { useDispatch } from "react-redux";
// // import SelectInput from "components/select";
// import toast from "react-hot-toast";
// const EditPrivacyPolicy = () => {
//   const dispatch = useDispatch();
//   const [value, setValue] = useState("");
//   const [formData, setFormData] = useState({
//     title: "",
//     description: "",
//   });
//   const getList = () => {
//     let request = {
//       language: "en",
//       title: formData?.title,
//       description: formData?.description,
//       policyType: 1,
//     };
//     dispatch(setPolicyByAdmin(request));
//   };
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   const handleSubmit = () => {
//     const { title } = formData;
//     const description = value;
//     let errors = {};

//     if (!title) {
//       errors.titleErr = "Please add title here";
//     }

//     if (!description) {
//       errors.descriptionErr = "Content is required";
//     }

//     if (Object.keys(errors).length > 0) {
//       setFormData({
//         ...formData,
//         error: "Please fill out all fields.",
//         ...errors,
//       });
//       return;
//     }
//     setFormData({
//       // Clearing form state
//       title: "",
//       description: "",
//     });

//     setFormData({ ...formData, error: "" });
//     getList();

//     toast.success("Saved");
//   };
//   console.log(formData, "formData");
//   return (
//     <>
//       <OwnerHeader
//         title={" Edit Privacy Policy"}
//         previousPage={"Privacy Policy"}
//         currentPage={"Edit Policy"}
//         previousPageUrl={"/privacy-policy"}
//       />
//       <div className="my-5">
//         <div className="row">
//           <div className="col-lg-6 col-md-6 col-sm-12">
//             <div
//               className="mt-3"
//               style={{
//                 fontFamily: "Circular Std Book Italic",
//                 color: "#8992a4",
//               }}
//             >
//               Create your notification for rental and user
//             </div>
//             <div className="myCar-label mt-3">Main Title</div>
//             <div className="form-group pt-3 ">
//               <Input
//                 name="title"
//                 value={formData?.title}
//                 onChange={(val) => {
//                   setFormData({ ...formData, title: val });
//                 }}
//               />
//               {formData.titleErr && (
//                 <span className="text-danger">{formData?.titleErr}</span>
//               )}
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-6 col-md-6 col-sm-12">
//             <div className="myCar-label">Description</div>

//             <div className="form-group pt-3">
//               <ReactQuill
//                 theme="snow"
//                 value={value}
//                 onChange={setValue}
//                 name="description"
//               />
//             </div>
//           </div>

//           <div
//             className="d-grid gap-2 col-8"
//             style={{
//               marginTop: "20px",
//             }}
//           >
//             <button
//               onClick={handleSubmit}
//               className="btn btn-warning "
//               type="button"
//               style={{
//                 fontFamily: "Circular Std Black",
//                 width: "20rem",
//                 marginTop: "auto",
//               }}
//             >
//               Save
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default EditPrivacyPolicy;
