// ApexChart.js
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";

const ChartApex = ({getstatistics}) => {
  const dispatch = useDispatch();
  const { statisticsList } = useSelector((state) => state.dashboard);
  const [months, setMonths] = useState([]);
  const [revenue, setrevenue] = useState([]);
  // console.log("statisticsList", statisticsList);
  console.log("revenue",statisticsList)
  useEffect(() => {
    getstatistics();
  }, []);
  useEffect(() => {
    if (statisticsList?.length > 1) {
      console.log("Hello")
      statisticsList?.map((data) => {
        console.log("Hi")

        let index = months?.findIndex((item) => item == data?.month);
        if (index == -1) {
                  console.log("Hi")

          setMonths((pre) => [...pre, data?.month]);
          setrevenue((pre) => [...pre, data?.totalRevenue]);
        }
      });
    }
  }, [statisticsList]);

  const chartData = {
    series: [
      {
        name: "All Revenue",
        data: statisticsList?.length > 0 ? revenue : [],
       
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "text",
        categories: months?.length > 0 ? months : [],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value / 1000 + 'k';
          },
        },
        
      },
      markers: {
        colors: ['#6CD2C2']
     },
     dataLabels: {
      style: {
        colors: ['#6CD2C2']
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        color: '#FFBB51',
        shadeTo: 'light',
        shadeIntensity: 0.65
      }
    },
    chart: {
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
      },
  },
  tooltip: {
    enabled: true,
},



    
   
      // tooltip: {
      //   x: {
      //     // format: "dd/MM/yy HH:mm",
      //   },
      // },
    },
  };
console.log("chart data",chartData)
  return (
    <div className="mt-5">
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="area"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ChartApex;

