import { Icon } from "@iconify/react";
import React from "react";
// import bg from '../../assets/img/dashBoardBg.png';
export default function CountBox(props) {
  const { bg, count, title, onClick, image, style } = props;
  return (
    <div className=""
      onClick={onClick}
      style={{
        minHeight: 150,
        width: "100%",
        position: "relative",
        borderTopRightRadius: 110,
        background: bg,
        borderTopLeftRadius: 25,
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 20,
        ...style,
      }}
    >
      <div className="container" style={{
          position: "absolute",
          bottom: 20,
          left: 0,
        }}>
      <div 
        
      >
        <div className="dashBoard-total-title">{count} </div>
        <div className="dashBoard-total-subTitle text-muted ">
          <div className="d-flex justify-content-between align-items-center">
            <div className="fs-6">{title}</div>
            <div className="">
              <Icon icon="fe:arrow-right" className="fs-6" />
            </div>
          </div>
        </div>
      </div>
      </div>
      <div
        style={{
          background: "white",
          height: 70,
          width: 70,
          borderRadius: 50,
          position: "absolute",
          top: 0,
          right: 15,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={image} alt="" width={"30"} />
      </div>
    </div>
  );
}
