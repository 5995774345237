import React, { useEffect, useRef, useState } from "react";
import profile from "../../assets/img/noProfile.jpg";
import { Icon } from "@iconify/react";
import Input from "../input";
import PhoneInput from "react-phone-input-2";
import { useResponsive } from "../../themes/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import { RENTEDWHEELSAPI } from "../../services/apiConstant";
import toast from "react-hot-toast";
import {
  getAllCarsByAdmin,
  updateRentedUserProfile,
} from "store/slice/dashboard";
import api from "../../services";
import { useParams } from "react-router-dom";
export default function UpdateRentalProfile() {
  const [updateRentalProfile, setUpdateRentalProfile] = useState({});
  const { allCarsList } = useSelector((state) => state.dashboard);
  console.log("allCarsList", allCarsList);
  const { screenType } = useResponsive();
  const inputProfile = useRef();
  const dispatch = useDispatch();
  const closeRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      let request = {
        userId: id,
        page: 1,
        perPage: 10,
        language: "en",
      };
      dispatch(getAllCarsByAdmin(request));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        firstName: allCarsList?.userdata?.firstName || "",
        lastName: allCarsList?.userdata?.lastName || "",
        phoneNumber: allCarsList?.userdata?.phoneNumber || "",
        email: allCarsList?.userdata?.email || "",
        profileImagePath: allCarsList?.userdata?.profileImagePath || "",
      }));
    }
  }, [id, allCarsList]);

  // Cleanup function to clear the state when the modal is closed
  useEffect(() => {
    return () => {
      setUpdateRentalProfile({});
    };
  }, []);

  // ------------------------upload profile image---------------
  const onProfileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    api
      .post(
        `${RENTEDWHEELSAPI.auth.changeProfileImage}?folderName=userProfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response?.data?.status === 1) {
          let filename = response?.data?.data[0]?.imageName;
          setUpdateRentalProfile((updateRentalProfile) => ({
            ...updateRentalProfile,
            profileImage: filename,
          }));
          let imageUrl = response.data.data[0].imagePath;
          if (imageUrl) {
            setUpdateRentalProfile((updateRentalProfile) => ({
              ...updateRentalProfile,
              profileImagePath: imageUrl,
            }));
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(() => {
        console.error("Error occurred while uploading image");
      });
  };
  // ------------------------ updateProfile-------------
  const handleUpdateProfile = () => {
    if (updateRentalProfile?.firstName.trim().length==" ") {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        firstNameErr: "Please enter your first name",
      }));
      return;
    }
    if (!updateRentalProfile?.firstName) {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        firstNameErr: "Please enter your first name",
      }));
      return;
    }
    if (updateRentalProfile?.lastName.trim().length==" ") {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        lastNameErr: "Please enter your last name",
      }));
      return;
    }
    if (!updateRentalProfile?.lastName) {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        lastNameErr: "Please enter your last name",
      }));
      return;
    }
    if (!updateRentalProfile?.phoneNumber) {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        phoneNumberErr: "Please enter your phone number",
      }));
      return;
    }
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );
    if (!updateRentalProfile?.email) {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        emailErr: "Please enter your email address",
      }));
      return;
    } else if (!updateRentalProfile?.email.includes("@")) {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        emailErr: "Please enter a valid email address",
      }));
      return;
    } else if (!updateRentalProfile?.email.includes(".")) {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        emailErr: "Please enter a valid email address",
      }));
      return;
    } else if (!validEmail.test(updateRentalProfile?.email)) {
      setUpdateRentalProfile((updateRentalProfile) => ({
        ...updateRentalProfile,
        emailErr: "Please enter a valid email address",
      }));
      return;
    }
    let request = {
      userId: id,
      firstName: updateRentalProfile?.firstName,
      lastName: updateRentalProfile?.lastName,
      countryCode: updateRentalProfile?.countryCode,
      phoneNumber: updateRentalProfile?.phoneNumber,
      email: updateRentalProfile?.email,
      profileImage: updateRentalProfile?.profileImage,
      language: "en",
    };
    dispatch(
      updateRentedUserProfile(request, closeRef, setUpdateRentalProfile)
    );
  };
  return (
    <>
      <div
        className="modal fade bd-example-modal-lg"
        id="updateRentalProfile"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div
            className={`modal-content py-2 ${
              screenType != "desktop" ? "" : "px-4"
            } `}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Edit Profile
              </h5>
              <button
                type="button"
                ref={closeRef}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
              <button
                type="button"
                className="smallBtn"
                style={{ width: 100 }}
                onClick={handleUpdateProfile}
              >
                Save
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div
                  className={screenType != "desktop" ? "col-sm-12" : "col-sm-4"}
                  style={{
                    display: "flex",
                    justifyContent: screenType != "desktop" ? "center" : "",
                    paddingBottom: screenType != "desktop" ? "1rem" : "",
                  }}
                >
                  <div
                    style={{
                      height: 195.3,
                      width: 195.3,
                      position: "relative",
                    }}
                  >
                    {updateRentalProfile?.profileImagePath ? (
                      <img
                        src={updateRentalProfile?.profileImagePath}
                        className="rounded-circle shadow-4"
                        style={{ width: "100%", height: "100%" }}
                        alt="Avatar"
                      />
                    ) : (
                      <img
                        src={profile}
                        className="rounded-circle shadow-4"
                        style={{ width: "100%", height: "100%" }}
                        alt="Avatar"
                      />
                    )}
                    <div
                      onClick={() => {
                        inputProfile.current && inputProfile.current.click();
                      }}
                      style={{
                        background: "#6CD2C2",
                        borderRadius: 35,
                        height: 50,
                        width: 50,
                        border: "4px solid white",
                        position: "absolute",
                        bottom: 10,
                        right: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon icon="circum:image-on" color="white" height={30} />
                    </div>
                    <input
                      ref={inputProfile}
                      style={{ display: "none", cursor: "pointer" }}
                      type="file"
                      accept="image/*"
                      onChange={onProfileChange}
                    />
                  </div>
                </div>

                <div
                  className={screenType != "desktop" ? "col-sm-12" : "col-sm-8"}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={50}
                          onChange={(val) => {
                            setUpdateRentalProfile((updateRentalProfile) => ({
                              ...updateRentalProfile,
                              firstNameErr: "",
                              firstName: val,
                            }));
                          }}
                          value={updateRentalProfile?.firstName}
                          placeholder={"First Name"}
                          icon={"fluent:person-28-regular"}
                          formError={updateRentalProfile?.firstNameErr}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={50}
                          onChange={(val) => {
                            setUpdateRentalProfile((updateRentalProfile) => ({
                              ...updateRentalProfile,
                              lastNameErr: "",
                              lastName: val,
                            }));
                          }}
                          value={updateRentalProfile?.lastName}
                          placeholder={"Last Name"}
                          icon={"fluent:person-28-regular"}
                          formError={updateRentalProfile?.lastNameErr}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group ">
                    <Input
                      onChange={(val) => {
                        setUpdateRentalProfile((updateRentalProfile) => ({
                          ...updateRentalProfile,
                          emailErr: "",
                          email: val,
                        }));
                      }}
                      value={updateRentalProfile?.email}
                      icon={"mdi-light:email"}
                      formError={updateRentalProfile?.emailErr}
                    />
                  </div>
                  <div className="form-group first ">
                    <div className="d-flex">
                      <div
                        style={{
                          background: "#f6f6f8 ",
                          paddingLeft: "0.7rem",
                          paddingTop: "0.7rem",
                        }}
                      >
                        <Icon
                          icon="ph:phone-call-light"
                          height={30}
                          color="#757c83"
                        />
                      </div>
                      <PhoneInput
                        placeholder={"(00) 0000-0000"}
                        country={"na"}
                        value={updateRentalProfile?.phone}
                        inputStyle={{
                          width: "100%",
                          border: "none",
                          height: "51px ",
                          fontSize: "19px",
                        }}
                        onChange={(value, inputdata) => {
                          setUpdateRentalProfile((updateRentalProfile) => ({
                            ...updateRentalProfile,
                            phoneNumberErr: "",
                            phone: value,
                            phoneNumber: value.slice(
                              inputdata?.dialCode?.length
                            ),
                            countryCode: inputdata?.dialCode,
                          }));
                        }}
                        inputClass="telInput"
                      />
                    </div>
                    <div
                      style={{
                        color: "red",
                        fontSize: 13,
                        marginTop: "0.5rem",
                        paddingLeft: 5,
                      }}
                      className="text-error"
                    >
                      {updateRentalProfile?.phoneNumberErr &&
                        updateRentalProfile?.phoneNumberErr}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
