
import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import { Icon } from "@iconify/react";
import { totalUsers } from "store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import noImage from "../../../assets/img/profile.png";
import PopOverSpinner from "components/popOverSpinner";
import ColoredButton from "components/button/colorFullButtons";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { useResponsive } from "themes/useResponsive";
import OwnerHeader from "layout/dashboardLayout/header";

const TotalUsers = () => {
  const [filterData, setFilterData] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [limit, setLimit] = useState(9);
  const [isLoading, setIsLoading] = useState(false);
  const { allUsers, totalCounts } = useSelector((state) => state.dashboard);
  const { screenType } = useResponsive();
console.log("user list ",allUsers)
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    getAllUsers();
  }, [filterData]);

  useEffect(() => {
    if (state?.length > 0) {
      if (state[0]?.startDate) {
        getAllUsers(
          limit,
          "",
          moment(state[0]?.startDate).format("YYYY/MM/DD"),
          moment(state[0]?.endDate).format("YYYY/MM/DD")
        );
      }
    }
  }, [state]);

  const getAllUsers = (perPage, search, startDate, endDate) => {
    setIsLoading(true);
    let request = {
      page: 1,
      perPage: perPage || limit,
      language: "en",
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      search: search || "",
      sortByDate: filterData.sortByDate,
    };
    dispatch(totalUsers(request, setIsLoading));
  };

  return (
    <>
      <OwnerHeader title={"Total Users"} />
      <div className="row align-items-center mt-5">
        <div className="col-lg-4 col-md-4 col-sm-4 fs-2 ">
          <span style={{ fontFamily: "Circular Std Medium", color: "#E54FFF" }}>
            {totalCounts?.totalUser}
          </span>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8 d-flex justify-content-end " style={{ marginTop: screenType == 'MOBILE' ? '-2.2rem' : '' }}>
          <form className="form-search form-inline rounded-5">
            <div className="dropdown">
              <button
                style={{ width: 100 ,paddingTop: 5 }}
                className="sort-List dropdown-toggle dropdown-toggle-split ml-2"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <label style={{ fontSize: "16px" }}> Sort By</label>
                <Icon icon={"formkit:down"} width={"20px"}     style={{ marginTop: -5 }}/>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setFilterData({ sortByDate: -1 });
                  }}
                >
                  New-Old
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setFilterData({ sortByDate: 1 });
                  }}
                >
                  Old-New
                </a>
              </div>
            </div>
            <div
              className="checklist bg-white ml-2 d-flex justify-content-center align-items-center"
              style={{ height: 48, width: 48,borderRadius:10 }}
            >
              <Icon
                icon="octicon:calendar-16"
                style={{ color: "black", cursor: "pointer" }}
                onClick={handleIconClick}
              />
             <div className="position-relative">
               {showCalendar && (
                <div
                  ref={calendarRef}
                  style={{
                    position: "absolute",
                    position: "absolute",
                    top: "2rem",
                    left: "-20rem",
                    zIndex: 5,
                  }}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                  />
                </div>
              )}
             </div>
            </div>
          </form>
        </div>
      </div>
      {isLoading && !allUsers?.users ? <PopOverSpinner /> : ""}
      <div className="row">
        {allUsers?.data?.users?.length >= 1 ? (
          <>
            {allUsers?.data?.users?.map((data, index) => (
              <div key={index} className="col-lg-4 col-md-12 col-sm-12 mt-2">
                <div class="pt-2 bg-body rounded-4">
                  <div className="checklist d-flex form-check align-items-center font-size-15 rounded-5 ml-3">
                    <img
                      src={data?.profileImagePath ? data?.profileImagePath : noImage}
                      alt="Avatar"
                      className="rounded-circle avatar-sm mb-1"
                      height={40}
                      width={40}
                      style={{ marginLeft: "-2rem" }}
                    />
                    &nbsp;
                    <div className="ms-2 my-2">
                      <div className="d-flex">
                        <h4
                          className="client-name fs-5 m-0"
                          style={{ fontFamily: "circular std Bold" }}
                        >
                          {data?.firstName+" "+data?.lastName}
                        </h4>
                      
                      </div>
                      <div
                        style={{
                          color: "#132649",
                          fontFamily: "Circular Std Book",
                          fontSize: "1rem",
                                                    marginTop: "-0.1rem",

                        }}
                      >
                        {data?.email}
                      </div>
                      <div className=""
                        style={{
                          color: "#132649",
                          fontFamily: "Circular Std Book",
                          fontSize: "0.8rem",
                          marginTop: "-0.1rem",
                        }}
                      >
                      {moment(data?.createdAt).format("Do MMM, YYYY")}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-center mt-5">
              {isLoading ? (
                <Spinner animation="border" style={{ color: "#6CD2C2" }} />
              ) : (
                allUsers?.totalCount > limit && (
                  <ColoredButton
                    onClick={() => {
                      let perPage = limit + 12;
                      setLimit(perPage);
                      getAllUsers(perPage);
                    }}
                    title="View More"
                    style={{ background: "#6CD2C2", width: "130px" }}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <div className="noData" style={{ height: "80vh" }}>
            No Data Found
          </div>
        )}
      </div>
    </>
  );
};

export default TotalUsers;
