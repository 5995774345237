import React, { useEffect, useRef, useState } from "react";
import { useResponsive } from "themes/useResponsive";
import { useNavigate } from "react-router-dom";
import { DateRange } from "react-date-range";
import { Icon } from "@iconify/react";
import { totalRentalCarList } from "store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import noImage from "../../../assets/img/no_image.jpg";
import AddRentalUserProfile from "components/dialog/addRentalUserProfile";
import ColoredButton from "components/button/colorFullButtons";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import OwnerHeader from "layout/dashboardLayout/header";

const TotalRentals = () => {
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const { totalRental, totalCounts } = useSelector((state) => state.dashboard);
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState({
    sortByDate: -1, // Default sort by New-Old
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [limit, setLimit] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const calendarRef = useRef(null);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    // Fetch rental list when date range changes
    if (state[0]?.startDate && state[0]?.endDate) {
      fetchRentalList(
        "",
        moment(state[0]?.startDate).format("YYYY/MM/DD"),
        moment(state[0]?.endDate).format("YYYY/MM/DD")
      );
    }
  }, [state]);

  useEffect(() => {
    fetchRentalList();
  }, [filterData]);

  const fetchRentalList = (perPage, startDate, endDate) => {
    setIsLoading(true);
    let request = {
      page: 1,
      perPage: perPage ? perPage : limit,
      language: "en",
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      search: "",
      sortByDate: filterData.sortByDate,
    };
    dispatch(totalRentalCarList(request, setIsLoading));
  };
  console.log("log", totalRental?.TotalCountCarOwner);
  return (
    <>
      <OwnerHeader title={"Total Rentals"} />
      <AddRentalUserProfile fetchRentalList={fetchRentalList} />
      <div className="row align-items-center mt-5 justify-content-center">
        <div className="col-lg-4 col-md-4 col-sm-3 fs-2 mt-3">
          <span style={{ fontFamily: "Circular Std Medium", color: "#39A0FF" }}>
            {totalRental?.TotalCountCarOwner}
          </span>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-9 d-flex justify-content-lg-end justify- mt-3 ">
          <form className="form-search form-inline rounded-5">
            <button
              type="button"
              className={screenType == "MOBILE" ? "btn fs-6" : "btn "}
              data-toggle="modal"
              data-target="#exampleModalCenterprofile"
            >
              Add New Rentals
            </button>

            <div className="dropdown">
              <button
                style={{ width: 100, paddingTop: 5 }}
                className="sort-List dropdown-toggle dropdown-toggle-split ml-2 "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <label
                  style={{ fontSize: "16px", color: "#132649" }}
                  className="myauto"
                >
                  {" "}
                  Sort By
                </label>
                <Icon
                  icon={"formkit:down"}
                  width={"20px"}
                  style={{ marginTop: -5 }}
                />
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  onClick={() => setFilterData({ sortByDate: -1 })}
                >
                  New-Old
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => setFilterData({ sortByDate: 1 })}
                >
                  Old-New
                </a>
              </div>
            </div>
            <div
              className="checklist bg-white rounded-4 ml-2 d-flex justify-content-center align-items-center"
              style={{ height: 48, width: 48,borderRadius:10 }}
            >
              <Icon
                icon="octicon:calendar-16"
                style={{ color: "black", cursor: "pointer" }}
                onClick={handleIconClick}
              />
              <div className="position-relative ">
                {showCalendar && (
                  <div
                    ref={calendarRef}
                    style={{
                      position: "absolute",
                      top: "2rem",
                      left: "-21rem",
                      zIndex: 5,
                    }}
                  >
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setState([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>

        {totalRental?.carDetails?.length >= 1 ? (
          <>
            {totalRental?.carDetails?.map((data, index) => (
              <div
                key={index}
                className="row p-2 bg-white d-flex justify-content-center rounded-3 px-0 my-1 mt-2"
              >
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="checklist d-flex align-items-center form-check font-size-15 rounded-5">
                      <img
                        src={
                          data?.profileImagePath
                            ? data?.profileImagePath
                            : noImage
                        }
                        alt="Avatar"
                        className="rounded-circle avatar-sm"
                        height={60}
                        width={60}
                        style={{ marginTop: "0.5rem", marginLeft: "-2.5rem" }}
                      />
                      &nbsp;
                      <div>
                        <div className="mt-2 d-flex">
                          <h4
                            className="client-name fs-5"
                            style={{ fontFamily: "circular std Bold" }}
                          >
                            {data?.firstName}
                          </h4>
                          <h4
                            className="client-name fs-5"
                            style={{ fontFamily: "circular std Bold" }}
                          >
                            &nbsp;{data?.lastName}
                          </h4>
                        </div>
                        <div
                          style={{
                            color: "#a6a7a9",
                            fontFamily: "Circular Std Book",
                            fontSize: "1rem",
                            marginTop: "-0.4rem",
                          }}
                        >
                          {data?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {data?.cars?.length > 0 && (
                      <div
                        className="checklist overflow-auto pr-2 form-check font-size-15 rounded-4 d-flex justify-content-end me-1 w-100 align-items-center mt-2"
                        style={{ background: "#F6F6F8" }}
                      >
                        {data?.cars?.map((car, i) => (
                          <img
                            key={i}
                            src={car?.carImage[0] ? car?.carImage[0] : noImage}
                            height={45}
                            width={45}
                            alt=""
                            className="rounded-circle avatar-sm"
                            style={{ marginLeft: "1rem" }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-2">
                    <div className="row mt-3">
                      <div
                        className="col-6 shadow bg-white rounded-3"
                        style={{
                          fontFamily: "Circular Std Book",
                          color: "#132649",
                        }}
                      >
                        {data?.carCount} Cars
                      </div>
                      <div className="col-6 col-sm-3 pl-5 text-end">
                        <Icon
                          onClick={() =>
                            navigate(`/rental-details/${data?._id}`)
                          }
                          icon="carbon:edit"
                          color="#FFB444"
                          height={30}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-center mt-5">
              {isLoading ? (
                <Spinner animation="border" style={{ color: "#6CD2C2" }} />
              ) : (
                totalRental?.TotalCountCarOwner > limit && (
                  <ColoredButton
                    onClick={() => {
                      let perPage = limit + 10;
                      setLimit(perPage);
                      fetchRentalList(perPage);
                    }}
                    title="View More"
                    style={{ background: "#6CD2C2", width: "130px" }}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <div className="noData" style={{ height: "80vh" }}>
            No Data Found
          </div>
        )}
      </div>
    </>
  );
};

export default TotalRentals;
