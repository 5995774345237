import React, { useEffect, useState } from "react";
import OwnerHeader from "layout/dashboardLayout/header";
import image from "../../../assets/img/paymentBg.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Icon } from "@iconify/react";
import { useResponsive } from "themes/useResponsive";
import { Modal, Button, Spinner } from "react-bootstrap";
import { getBlogsByAdmin } from "store/slice/blogsData";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import noImage from "../../../assets/img/no_image.jpg";
import { Dropdown } from "react-bootstrap";
import { deleteBlogByAdmin } from "store/slice/blogsData";
import ColoredButton from "components/button/colorFullButtons";

const Blogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(6);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const [blogToDelete, setBlogToDelete] = useState(null); // State to store blog ID to be deleted
  const { allBlogsList } = useSelector((state) => state.blogsData);
  const { screenType } = useResponsive();
  // console.log("  allBlogsList?.totalCount",  allBlogsList?.totalCount > limit);

  useEffect(() => {
    fetchBlogDataList();
  }, [limit]);

  const fetchBlogDataList = () => {
    setIsLoading(true);
    let request = {
      page: 1,
      limit: limit,
      language: "en",
    };
    dispatch(getBlogsByAdmin(request, setIsLoading));
  };

  const handleDelete = (id) => {
    setBlogToDelete(id); // Set the ID of the blog to be deleted
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const confirmDelete = () => {
    if (blogToDelete) {
      let request = {
        language: "en",
        blogId: blogToDelete,
      };
      dispatch(deleteBlogByAdmin(request));
      setShowDeleteModal(false); // Close the delete confirmation modal
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="position-relative" style={{display:"flex" ,justifyContent:"end"}}>
        <Icon
          // className="position-absolute"
          icon="entypo:dots-two-vertical"
          color="#132649"
          style={{
            cursor: "pointer",
            top: 0,
            right:
              screenType == "MOBILE"
                ? "-1.2rem"
                : screenType == "TABLET"
                  ? "-1.2rem"
                  : 0,
          }}
        />
      </div>
    </a>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  return (
    <>
      <OwnerHeader title={"Blogs"} />
      <div
        className="card mt-5"
        style={{
          background: "linear-gradient(to right, #FFF5E6, #EAF6F4 50%)",

          width: "100%",
          height: "150px",
          border: "none",
          marginTop: "10",
        }}
      >
        <div className="my-auto">
          <center className="my-auto">
            <h4
              className=""
              style={{
                color: "#00071A",
                fontFamily: "Circular Std Bold",
                fontSize: screenType === "MOBILE" ? "1.2rem" : "2rem",

                fontWeight: 400,
              }}
            >
              Blogs
            </h4>
          </center>
          {screenType === "MOBILE" ? (
            <div
              type="button"
              style={{
                background: "#FFBB51",
                borderRadius: 35,
                height: 40,
                width: 40,
                position: "absolute",
                top: "2.9rem",
                right: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon
                onClick={() => navigate("/add-blogs")}
                icon="gridicons:create"
                color="white"
                height={20}
              />
            </div>
          ) : (
            <button
              onClick={() => navigate("/add-blogs")}
              style={{
                position: "absolute",
                bottom: "3.5rem",
                right: "2rem",
                fontFamily: "Circular Std Black",
              }}
              className="btn btn-primary btn-sm"
            >
              Create Blog
            </button>
          )}
        </div>
      </div>
      <div>
        <div className="row">
          {allBlogsList?.data?.length >= 1 ? (
            <>
              {allBlogsList?.data?.map((data, index) => {
                return (
                  <>
                    <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                      <div
                        className={` ${screenType === "MOBILE" ? "pt-4" : "p-2"}`}
                      >
                        <div
                          className="shadow p-3 mb-2 bg-white rounded row"
                          style={{ height: "auto", cursor: "pointer" }}
                          // onClick={() => navigate(`/blog-details/${data?.id}`)}
                        >
                          <Dropdown align={"bottom"} style={{justifyContent:"end",display:"flex"}}>
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components"
                            >
                              Custom toggle
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomMenu} style={{ marginLeft: "-10rem"}} >
                              <Dropdown.Item
                                onClick={() => {
                                  navigate(`/edit-blogs/${data?.id}`);
                                }}
                              >
                                <span
                                  className="pl-4"
                                  style={{
                                    fontFamily: "Circular Std Black",  
                                  }}
                                >
                                  Update
                                </span>
                              </Dropdown.Item>
                              <hr />
                              <Dropdown.Item
                                onClick={() => {
                                  handleDelete(data?.id);
                                }}
                              >
                                <span
                                  className="pl-4"
                                  style={{
                                    fontFamily: "Circular Std Black",
                                  }}
                                >
                                  Delete
                                </span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="position-relative">
                              <img
                                src={
                                  data?.blogThumbnail
                                    ? data?.blogThumbnail
                                    : noImage
                                }
                                width={"100%"}
                                height={"125rem"}
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "1rem",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="col-lg-8 col-md-12 col-sm-12"
                            style={{
                              marginTop: screenType === "MOBILE" ? "1REM" : "",
                            }}
                          >
                            <div className="row mt-3 align-items-center">
                              <div className="col-lg-5">
                                <div className="blog-date">
                                  {moment(data?.createdAt).format(
                                    "DD MMM,YYYY"
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-7">
                                <div>
                                  <small className="smallText text-end">
                                    {data?.mainTitle}
                                  </small>
                                </div>
                              </div>
                              <div className="col-12">
                                <div
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <h5 className="blog-main-heading ">
                                    {data?.addTags}
                                  </h5>
                                  <h6
                                    className="blog-date"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      width: "200px",
                                    }}
                                  >
                                    {data?.writeBlogs}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              <div className="d-flex justify-content-center mt-5">
                {isLoading ? (
                  <Spinner animation="border" style={{ color: "#6CD2C2" }} />
                ) : (
                  allBlogsList?.totalCount > limit && (
                    <ColoredButton
                      onClick={() => {
                        setLimit((prevLimit) => prevLimit + 12);
                        fetchBlogDataList();
                      }}
                      title="View More"
                      style={{
                        background: "#6CD2C2",
                        width: "130px",
                      }}
                    />
                  )
                )}
              </div>
            </>
          ) : (
            <div className="noData" style={{ height: "80vh" }}>
              No Data Found
            </div>
          )}
        </div>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this blog?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Blogs;
