import "./index.css";

import { Link, Outlet } from "react-router-dom";
import logo from "../../assets/img/authLogo.png";
export default function AuthLayout() {
  return (
    <>
      <div className="d-flex justify-content-center">
        <div
          className="contents order-2 order-md-1"
          style={{ background: "white" }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center py-3">
              <div className="col-md-8" style={{ margin: "5rem" }}>
                <div>
                  <Link to="/">
                    <img src={logo} height={"20%"} width={"40%"} />
                  </Link>
                </div>

                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
