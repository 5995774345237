export const RENTEDWHEELSAPI = {
  //auth
  auth: {
    adminLogin: "/admin/adminLogin",
    userSignup: "/users/userSignup",
    userSignin: "/users/userSignin",
    getForgotOtp: "/users/send-otp",
    verfiyForgotOtp: "/users/verify-otp",
    resetPassword: "/users/reset-password",
    changePassword: "/admin/adminChangePassword",
    getUserProfile: "/users/getUserProfile",
    updateAdminProfile: "/admin/adminUpdateProfile",
    changeProfileImage: "/users/uploadFileOnLocal",
    verifiedUserDetail: "/users/verify-user/",
    // changeProfileImage: '/users/uploadFileOnLocal',
  },
  blogs: {
    addBlogs: "/admin/addBlogByAdmin",
    getBlogs: "/admin/getAllBlogs",
    blogDescription: "/admin/getBlogDetails",
    updateBlog: "/admin/updateBlogByAdmin",
    deleteBlog: "/admin/deleteBlog",
  },
  coupons: {
    getcouponsList: "/admin/listCoupons",
    addCoupon: "/admin/addCoupon",
    deleteCoupon: "/admin/deleteCoupon",
    updateCouponsByAdmin: `/admin/updateCoupon`,
    getCouponCodeById: "/admin/getCouponCodeById",
  },
  policy: {
    getTheListOfPolicy: `/admin/getTheListOfPolicy`,
    setPolicyByAdmin: "/admin/setPolicyByAdmin",
  },
  notification: {
    sendNotificationsByAdmin: "/admin/sendNotificationByAdmin",
  },
  dashboard: {
    totalCount: "/admin/adminDashboardTotal",
    totalRentalCarList: "/admin/totalRentalCar",
    totalUsers: "/admin/allUsers",
    TotalCommissionPaid: "/payment/getTheListOfCommisionPaid",
    addRentalUserByAdmin: "/admin/adminAddRentalUser",
    getAllCarsByAdmin: "/admin/getAllCarsByCarOwner",
    updateRentedUserProfile: "/admin/adminUpdateRentedUserProfile",
    getTransactionList: "/payment/getTransactionListForAdmin",
    getTheGraph:"/admin/getTheGraphOfTotalRevenue",
    generateThePdf:"/payment/generateThePdfOfRevenue"
  },
};

export const API_RESPONSE = {
  SUCCESS: 1,
  FAILURE: 0,
};
