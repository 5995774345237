import { Icon } from "@iconify/react";
import OwnerHeader from "layout/dashboardLayout/header";
import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import { useNavigate } from "react-router-dom";
import profileImage from "../../../assets/img/GirlLogo.png";
import { TotalCommissionPaid } from "store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PopOverSpinner from "components/popOverSpinner";
import ColoredButton from "components/button/colorFullButtons";
import { Spinner } from "react-bootstrap";

const TotalCommission = () => {
  const [filterData, setFilterData] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const calendarRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { totalCommission } = useSelector((state) => state.dashboard);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };
  useEffect(() => {
    getcommissionList();
  }, []);
  useEffect(() => {
    if (state?.length > 0) {
      if (state[0]?.startDate) {
        getcommissionList(
          "",
          moment(state[0]?.startDate)?.format("MM/DD/YYYY"),
          moment(state[0]?.endDate)?.format("MM/DD/YYYY")
        );
      }
    }
  }, [state]);
  console.log("state", state);
  const getcommissionList = (perPage, startDate, endDate) => {
    setIsLoading(true);
    let request = {
      page: 1,
      // perPage: perPage ? perPage : 10,
      perPage: perPage ? perPage : limit,
      language: "en",
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      search: "",
    };
    dispatch(TotalCommissionPaid(request, setIsLoading));
  };
  return (
    <>
      <OwnerHeader title={"Commission paid "} />
      <div className="row align-items-center mt-5">
        <div className="col-sm-8 fs-2 mt-3">
          <span style={{ fontFamily: "Circular Std Medium", color: "#FFB444" }}>
            52.2K
          </span>
        </div>
        <div className="col-sm-4 d-flex justify-content-end mt-3 ">
          <form className="form-search form-inline rounded-5">
            <div className="dropdown">
              <button
                style={{ width: 150 }}
                className="sort-List dropdown-toggle dropdown-toggle-split ml-2"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <label style={{ fontSize: "16px" }}>All</label>
                <Icon icon={"formkit:down"} width={"20px"} />
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setFilterData((filterData) => ({
                      ...filterData,
                      sortByDate: -1,
                    }));
                  }}
                >
                  New-Old
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setFilterData((filterData) => ({
                      ...filterData,
                      sortByDate: 1,
                    }));
                  }}
                >
                  Old-New
                </a>
                {/* <a className="dropdown-item" href="#">
                        Something else here
                      </a> */}
              </div>
            </div>
            <div
              className="checklist bg-white rounded-4 ml-2 d-flex justify-content-center align-items-center"
              style={{ height: 40, width: 40 }}
            >
              <Icon
                icon="octicon:calendar-16"
                style={{ color: "black", cursor: "pointer" }}
                onClick={handleIconClick}
              />
              {showCalendar && (
                <div
                  ref={calendarRef}
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "0",
                    zIndex: 5,
                  }}
                >
                  {/* <Calendar onChange={handleCalendarChange} value={date} /> */}
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      {isLoading && !totalCommission ? <PopOverSpinner /> : ""}
      <div className="row">
        {totalCommission?.length >= 1 ? (
          <>
            {totalCommission?.map((data, index) => {
              return (
                <>
                  <div className="col-lg-4 col-md-12 col-sm-12 mt-4">
                    <div class="shadow pt-2 bg-body rounded">
                      <div className="d-flex justify-content-between">
                        <div className="checklist d-flex form-check font-size-15 rounded-5 ml-3">
                          <img
                            src={
                              data?.profileImage
                                ? data?.profileImage
                                : profileImage
                            }
                            alt="Avatar"
                            className="rounded-circle avatar-sm mb-1"
                            height={50}
                            width={50}
                            style={{ marginLeft: "-2rem" }}
                          />
                          &nbsp;
                          <div>
                            <div className="">
                              <h4
                                className="client-name fs-5"
                                style={{ fontFamily: "circular std Bold" }}
                              >
                                {data?.userName}
                              </h4>
                            </div>
                            <div
                              style={{
                                color: "#a6a7a9",
                                fontFamily: "Circular Std Book",
                                fontSize: "1rem",
                                marginTop: "-0.4rem",
                              }}
                            >
                              {moment(data?.createdAt).format("Do MMM, YYYY")}
                            </div>
                          </div>
                        </div>
                        <div
                          className="mr-3 mt-2"
                          style={{ fontFamily: "Circular Std" }}
                        >
                          <span style={{ color: "#F1AE01" }}>N$</span>
                          <span style={{ color: "#132649" }}>
                            {data?.platformFees}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="d-flex justify-content-center mt-5">
              {isLoading ? (
                <Spinner animation="border" style={{ color: "#6CD2C2" }} />
              ) : (
                totalCommission?.TotalCommissionList > limit && (
                  <ColoredButton
                    onClick={() => {
                      let perPage = limit + 10;
                      setLimit(perPage);
                      getcommissionList(perPage);
                    }}
                    title="View More"
                    style={{ background: "#6CD2C2", width: "130px" }}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <div className="noData" style={{ height: "80vh" }}>
            No Data Found
          </div>
        )}
      </div>
    </>
  );
};

export default TotalCommission;
