import React, { useEffect } from "react";
import OwnerHeader from "layout/dashboardLayout/header";
import image from "../../../assets/img/paymentBg.png";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "themes/useResponsive";
import { getTheListOfPolicy } from "store/slice/policy";
import { useDispatch, useSelector } from "react-redux";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const { getPolicy } = useSelector((state) => state.policy);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    let request = {
      policyType: 1,
      language: "en",
    };
    dispatch(getTheListOfPolicy(request));
  };

  const isDescriptionBlank = !getPolicy?.description || getPolicy?.description.trim() === '';

  return (
    <>
      <OwnerHeader title={"Privacy Policy"} />
      <div
        className="card mt-5"
        style={{
          background: "linear-gradient(to right, #FFF5E6, #EAF6F4 50%)",

          width: "100%",
          height: "150px",
          border: "none",
          position: "relative",
        }}
      >
        <div>
          <center>
            <h4
              style={{
                color: "#00071A",
                fontFamily: "Circular Std Bold",
                fontSize: "2rem",
                fontWeight: 40,
                paddingTop:" 3.2rem",
                marginLeft:"-2.2rem"
              }}
            >
              Privacy Policy
            </h4>
          </center>

          {screenType === "MOBILE" || screenType === "TABLET" ? (
            <div
              type="button"
              style={{
                background: "#FFBB51",
                borderRadius: 35,
                height: 40,
                width: 40,
                position: "absolute",
                top: "2.9rem",
                right: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon
                onClick={() => navigate("/edit-PrivacyPolicy")}
                icon="gravity-ui:pencil-to-line"
                color="white"
                height={20}
              />
            </div>
          ) : (
            isDescriptionBlank ? (
              <button
                onClick={() => navigate("/edit-PrivacyPolicy")}
                style={{
                  position: "absolute",
                  bottom: "3.5rem",
                  right: "4rem",
                  fontFamily: "Circular Std Black",
                }}
                className="btn btn-primary btn-sm"
              >
                Add Policy
              </button>
            ) : (
              <button
                onClick={() => navigate("/edit-PrivacyPolicy")}
                // onClick={() => navigate(`/edit-PrivacyPolicy/${id}`)}
                style={{
                  position: "absolute",
                  bottom: "3.5rem",
                  right: "4rem",
                  fontFamily: "Circular Std Black",
                }}
                className="btn btn-primary btn-sm"
              >
                Edit Policy
              </button>
            )
          )}
        </div>
      </div>

      <div className="container">
        <div className="mt-5 mb-5 H4detail">
          <h4 className="paragraphH4">{getPolicy?.title}</h4>
          <div
            className="H4detail"
            dangerouslySetInnerHTML={{ __html: getPolicy?.description }}
          />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
