import React, { useEffect, useState } from "react";
import OwnerHeader from "layout/dashboardLayout/header";
import { Icon } from "@iconify/react";
import { getTransactionList } from "store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import profileImage from "../../../assets/img/no_image.jpg";
import moment from "moment";
import ColoredButton from "components/button/colorFullButtons";
import { Spinner } from "react-bootstrap";
import { useResponsive } from "themes/useResponsive";

const Payment = () => {
  const dispatch = useDispatch();
  const { transactionList } = useSelector((state) => state.dashboard);
  const {screenType} = useResponsive();
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListloading] = useState(false);
  // const [filterData, setFilterData] = useState({});
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getPaymentList();
    setIsListloading(true);
  }, []);
 

  const getPaymentList = (perPage, search) => {
    setIsLoading(true);
    let request = {
      language: "en",
      page: 1,
      perPage: perPage ? perPage : limit,
      search: search ? search : "",
    };

    dispatch(getTransactionList(request, setIsLoading, setIsListloading));
  };

  return (
    <>
      <OwnerHeader title={"Payment"} />
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div
            className="card mt-5 rounded-4"
            style={{
              background: "#E9F8F6",
              height: "9rem",
              border: "none",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: 20,
                left: 20,
              }}
            >
              <div className="dashBoard-total-title">
                <span style={{ fontSize: "1.5rem", color: "#FFBB51" }}>N$</span>
                {transactionList?.totalRevenue}
              </div>
              <div className="dashBoard-total-subTitle text-muted">
                Total Revenue
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-12 col-md-12 col-sm-12 ">
          <div
            className="card mt-5 rounded-4"
            style={{
              background: "#E9F8F6",
              height: "9rem",
              border: "none",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: 20,
                left: 20,
              }}
            >
              <div className="dashBoard-total-title">
                <span style={{ fontSize: "1.5rem", color: "#FFBB51" }}>N$</span>
              </div>
              <div className="dashBoard-total-subTitle text-muted">
                Total Commissions
              </div>
            </div>
          </div>
        </div> */}
      
      </div>
      <div className="row" style={{marginTop: screenType=='desktop' || screenType=='TABLET' ?'2rem': ''}}>
        <div className="col-lg-4 col-md-4 col-sm-4 fs-2  carOwner-tab-title" style={{marginTop: screenType=='MOBILE'?'2rem': ''}}> History</div>
        <div className="col-lg-8 col-md-8 col-sm-8 d-flex justify-content-end"  style={{ marginTop: screenType == 'MOBILE' ? '-2.5rem' : '' }}>
          <form className="form-search form-inline rounded-5">
            <div
              className="px-4"
              style={{ background: "white", borderRadius: 10 }}
            >
              <Icon icon={"ri:search-line"} height={30} color="#a0a6b1" />
              <input
                className="payment-input"
                placeholder="Trans. Id"
                style={{ width: "140px" }}
                onChange={(e) => {
                  getPaymentList("", e.target.value);
                }}
              />
            </div>
          </form>
        </div>
      </div>

      <div className="row">
        {transactionList?.data?.length >= 1 ? (
          <>
            {transactionList?.data?.map((data, index) => {
              return (
                <>
                  <div className="col-lg-4 col-md-12 col-sm-12 ">
                    <div className=" border border-2 p-1 bg-white rounded-4 mt-3">
                      <div className="shadow bg-white rounded">
                        <div className="checklist d-flex form-check font-size-15 rounded-5 mt-3 align-items-center">
                          <img
                            src={data?.userData?.profileImage ? data?.userData?.profileImage : profileImage}
                            alt="Avatar"
                            className="rounded-circle avatar-sm mt-1"
                            height={"60px"}
                            width={"60px"}
                            style={{ marginLeft: "-1rem" }}
                          />
                          &nbsp;
                          <div>
                            <div>
                              <h4
                                className="client-name fs-5"
                                style={{
                                  fontFamily: "circular std Bold",
                                }}
                              >
                                {data?.userData?.userName}
                              </h4>
                            </div>
                            <div
                              style={{
                                color: "#132649",
                                fontFamily: "Circular Std Book",
                                fontSize: "1.2rem",
                                marginTop: "-0.4rem",
                              }}
                            >
                              <small className="d-inline text-muted">
                                Transaction ID :{data?.transactionId}
                              </small>
                              &nbsp;&nbsp;
                              <small className="d-inline text-muted">.</small>
                              &nbsp;&nbsp;
                              <small className="d-inline text-muted">
                                {moment(data?.createdDateTime).format(
                                  "Do MMM, YYYY"
                                )}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mt-3 mb-2 text-muted">
                        <span
                          style={{
                            fontFamily: "Circular Std Bold ",
                            color: "#F1AE01",
                          }}
                        >
                          N$
                        </span>
                        <span
                          style={{
                            fontFamily: "Circular Std Bold ",
                            color: "#132649",
                          }}
                        >
                          {data?.totalAmount}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="d-flex justify-content-center mt-5">
              {isLoading ? (
                <Spinner animation="border" style={{ color: "#6CD2C2" }} />
              ) : (
                transactionList?.totalCount > limit && (
                  <ColoredButton
                    onClick={() => {
                      let perPage = limit + 10;
                      setLimit(perPage);
                      getPaymentList(perPage);
                    }}
                    title="View More"
                    style={{ background: "#6CD2C2", width: "130px" }}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <div className="noData" style={{ height: "50vh" }}>
            No Data Found
          </div>
        )}
      </div>
    </>
  );
};

export default Payment;
