
import React, { useEffect, useRef, useState } from "react";
import OwnerHeader from "layout/dashboardLayout/header";
import { DateRange } from "react-date-range";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "themes/useResponsive";
import profileImage from "../../../assets/img/GirlLogo.png";
import moment from "moment";
import CalendarDialog from "components/dialog/calendarDialog";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionList } from "store/slice/dashboard";
import { Spinner } from "react-bootstrap";
import ColoredButton from "components/button/colorFullButtons";

const TotalRevenue = () => {
  const { transactionList } = useSelector((state) => state.dashboard);
  const { screenType } = useResponsive();
  const [filterData, setFilterData] = useState({
    sortByDate: 1, // Default sort by Old-New
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListloading] = useState(false);
  const calendarRef = useRef(null);
  const [limit, setLimit] = useState(6);
  const dispatch = useDispatch();
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    // Fetch transaction list when date range changes
    if (state[0]?.startDate && state[0]?.endDate) {
      getPaymentList("", "", moment(state[0]?.startDate), moment(state[0]?.endDate));
    }
  }, [state]);

  useEffect(() => {
    // Fetch transaction list when sorting changes
    getPaymentList();
  }, [filterData]);

  const getPaymentList = (perPage, search, startDate, endDate) => {
    setIsLoading(true);
    let request = {
      language: "en",
      page: 1,
      perPage: perPage ? perPage : limit,
      startDate: startDate ? startDate.format("YYYY/MM/DD") : "",
      endDate: endDate ? endDate.format("YYYY/MM/DD") : "",
      search: search ? search : "",
      sortByDate: filterData.sortByDate,
    };

    dispatch(getTransactionList(request, setIsLoading, setIsListloading));
  };

  const handleSortChange = (sortValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      sortByDate: sortValue,
    }));
  };

  return (
    <>
      <OwnerHeader title={"Total Revenue"} />
      <CalendarDialog />
      <div className="row align-items-center mt-5">
        <div className="col-lg-3 col-md-3 col-sm-3 fs-2 mt-3">
          <span style={{ fontFamily: "Circular Std Medium", color: "#132649" }}>
            N$
          </span>{" "}
          <span style={{ fontFamily: "Circular Std Medium", color: "#62E26F" }}>
            {transactionList?.totalRevenue}K
          </span>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-9 d-flex justify-content-end mt-3 ">
          <form className="form-search form-inline rounded-5">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModalCalendar"
            >
              Sales Report
            </button>
            <div className="dropdown">
              <button
                style={{ width: 100 }}
                className="sort-List dropdown-toggle dropdown-toggle-split ml-2"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <label style={{ fontSize: "16px" }}> Sort By</label>
                <Icon icon={"formkit:down"} width={"20px"} />
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  className="dropdown-item"
                  onClick={() => handleSortChange(-1)}
                >
                  New-Old
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => handleSortChange(1)}
                >
                  Old-New
                </a>
              </div>
            </div>
            <div
              className="checklist bg-white rounded-4 ml-2 d-flex justify-content-center align-items-center"
              style={{ height: 48, width: 48,borderRadius:10 }}
            >
              <Icon
                icon="octicon:calendar-16"
                style={{ color: "black", cursor: "pointer" }}
                onClick={handleIconClick}
              />
            <div className="position-relative">
            {showCalendar && (
                <div
                  ref={calendarRef}
                  style={{
                    position: "absolute",
                    top: "2rem",
                    left: "-20rem",
                    zIndex: 5,
                  }}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                  />
                </div>
              )}
            </div>
            </div>
          </form>
        </div>

        {transactionList?.data?.length >= 1 ? (
          <>
            {transactionList?.data?.map((data, index) => (
              <div key={index} className="shadow bg-white rounded mt-2">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-12 mb-3">
                    <div className="d-flex justify-content-between  align-items-center">
                      <div className="checklist d-flex form-check font-size-15 rounded-5">
                        <img
                          src={
                            data?.sellerData?.sellerPtofileImageUrl
                              ? data?.userData?.sellerPtofileImageUrl
                              : profileImage
                          }
                          alt="Avatar"
                          className="rounded-circle avatar-sm"
                          height={60}
                          width={60}
                          style={{ marginTop: "1rem", marginLeft: "-2rem" }}
                        />
                        &nbsp;
                        <div>
                          <label
                            className="ms-1 task-title mt-4"
                            style={{
                              fontFamily: "Circular Std Bold",
                              fontSize: screenType === "small" ? "16px" : "18px",
                              color: "#132649",
                            }}
                          >
                            {data?.sellerData?.sellerName}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <div
                                          className={screenType =="MOBILE" ? "me-2 order-lg-0 order-sm-3 text-center mt-3" : " me-2 order-lg-0 order-sm-3 text-centermt-0"}


                          style={{
                            color: "#5c6064",
                            fontFamily: "Circular Std Book",
                            fontSize: screenType === "MOBILE" ? "12px" : "13px",
                          }}
                        >
                          TransactionID:{data?.transactionId}
                        </div>
                        <div
                  className={screenType =="MOBILE" ? "mt-3" : "mt-0"}
                  style={{
                            color: "#5c6064",
                            fontFamily: "Circular Std Book",
                            fontSize: screenType === "MOBILE" ? "12px" : "15px",
                          }}
                        >
                          {moment(data?.createdDateTime).format("Do MMM, YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="d-flex align-items-center justify-content-evenly mt-3">
                      <div className="checklist d-flex form-check font-size-15 rounded-5">
                        <img
                          src={
                            data?.userData?.profileImage
                              ? data?.userData?.profileImage
                              : profileImage
                          }
                          alt="Avatar"
                          className="rounded-circle avatar-sm"
                          height={40}
                          width={40}
                          style={{ marginLeft: "-2rem" }}
                        />
                        &nbsp;
                        <div>
                          <label
                            style={{
                              fontFamily: "Circular Std Bold",
                              fontSize: screenType === "small" ? "20px" : "15px",
                              color: "#132649",
                            }}
                          >
                            {data?.userData?.userName}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-3">
                        <h4
                          style={{
                            color: "#132649",
                            fontFamily: "Circular Std Medium",
                            fontWeight: 300,
                            fontSize: screenType === "small" ? "14px" : "16px",
                          }}
                        >
                          <span
                            style={{
                              color: "#FFBB51",
                              fontFamily: "Circular Std",
                              fontWeight: 100,
                            }}
                          >
                            N$
                          </span>
                          <span style={{ fontWeight: 600, fontSize: "18px" }}>
                            {data?.totalAmount}
                          </span>
                          &nbsp;
                          <span
                            style={{
                              color: "#5c6064",
                              fontSize: "15px",
                              fontFamily: "Circular Std ",
                            }}
                          >
                            Per day
                          </span>
                        </h4>
                        <div
                          style={{
                            color: "#5c6064",
                            fontSize: "12px",
                            fontWeight: 400,
                            fontFamily: "Circular Std Book Italic",
                          }}
                        >
                          Incl.25% commission(<span>N${data?.commissionAmount}</span>)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-center mt-5">
              {isLoading ? (
                <Spinner animation="border" style={{ color: "#6CD2C2" }} />
              ) : (
                transactionList?.totalCount > limit && (
                  <ColoredButton
                    onClick={() => {
                      let perPage = limit + 10;
                      setLimit(perPage);
                      getPaymentList(perPage);
                    }}
                    title="View More"
                    style={{ background: "#6CD2C2", width: "130px" }}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <div className="noData" style={{ height: "80vh" }}>
          No transactions found.
          </div>
        )}
      </div>
    </>
  );
};

export default TotalRevenue;

