import React, { useEffect, useState } from "react";
import { useResponsive } from "themes/useResponsive";
import noImage from "../../../assets/img/no_image.jpg";
import Image from "../../../assets/img/rental.png";
import { getAllCarsByAdmin } from "store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Carousel } from "react-bootstrap"; // Correct import
import { Icon } from "@iconify/react";

const CarBox = (props) => {
  const { data } = props;
  const { screenType } = useResponsive();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { allCarsList } = useSelector((state) => state.dashboard);
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // const handleDelete = (id) => {
  //   setBlogToDelete(id); // Set the ID of the blog to be deleted
  //   setShowDeleteModal(true); // Show the delete confirmation modal
  // };

  // const confirmDelete = () => {
  //   if (blogToDelete) {
  //     let request = {
  //       language: "en",
  //       blogId: blogToDelete,
  //     };
  //     dispatch(deleteBlogByAdmin(request));
  //     setShowDeleteModal(false); // Close the delete confirmation modal
  //   }
  // };
  useEffect(() => {
    fetchCarList();
  }, []);

  const fetchCarList = () => {
    let request = {
      userId: id,
      page: 1,
      perPage: 10,
      language: "en",
    };
    dispatch(getAllCarsByAdmin(request));
  };
  return (
    <>
      <div className="latesCars-Box mt-5">
        <div
          className="latestCars-imageBox"
          style={{
            borderRadius: 10,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Carousel
            controls={false}
            // interval={3000}
            slide={false}
            activeIndex={index}
            onSelect={handleSelect}
          >
            {data?.carImagesUrl &&
            data?.carImagesUrl?.length > 0 ? (
              data?.carImagesUrl?.map((item, i) => {
                return (
                  <Carousel.Item key={i}>
                    <img
                      className="d-block"
                      src={item}
                      width={"100%"}
                      style={{ objectFit: "cover", borderRadius: 15 }}
                      height={"169.76px"}
                      alt="Car slide"
                    />
                  </Carousel.Item>
                );
              })
            ) : (
              <div className="carousel-item active">
                <img
                  className="d-block"
                  style={{ objectFit: "cover", borderRadius: 15 }}
                  src={noImage}
                  width={"100%"}
                  height={"169.76px"}
                  alt="First slide"
                />
              </div>
            )}
          </Carousel>
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              borderRadius: 15,
              background: "white",
              display: "inline-flex",
              padding: 2,
            }}
          >
            <span className="badge text-dark d-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.3em"
                height="1.3em"
                viewBox="0 0 32 32"
              >
                <path
                  fill="#F1AE01"
                  d="m18.7 4.627l2.247 4.31a2.27 2.27 0 0 0 1.686 1.189l4.746.65c2.538.35 3.522 3.479 1.645 5.219l-3.25 2.999a2.225 2.225 0 0 0-.683 2.04l.793 4.398c.441 2.45-2.108 4.36-4.345 3.24l-4.536-2.25a2.282 2.282 0 0 0-2.006 0l-4.536 2.25c-2.238 1.11-4.786-.79-4.345-3.24l.793-4.399c.14-.75-.12-1.52-.682-2.04l-3.251-2.998c-1.877-1.73-.893-4.87 1.645-5.22l4.746-.65a2.23 2.23 0 0 0 1.686-1.189l2.248-4.309c1.144-2.17 4.264-2.17 5.398 0"
                ></path>
              </svg>
              &nbsp;
              <div style={{ fontSize: 17 }}> {data?.ratingdata?.rating ? data?.ratingdata?.rating : 5}</div>
            </span>
          </div>
          {/* <div className="myCar-deleteIcon">
            <Icon
              icon="ant-design:delete-outlined"
              color="white"
              height={30}
              // onClick={() => handleDelete(data?.id)}
            />
          </div> */}
        </div>
        <div className="pl-2">
          <div className="latestCars-title pt-2 ">{data?.modelName}</div>
          <small className="d-inline text-muted ">{data?.transmission}</small>
          &nbsp;&nbsp; <small className="d-inline text-muted">.</small>
          &nbsp;&nbsp;
          <small className="d-inline text-muted">{data?.carFuelType ? data?.carFuelType : "Diesel"}</small>&nbsp;&nbsp;
          <small className="d-inline text-muted">.</small>&nbsp;&nbsp;
          <small className="d-inline text-muted">{data?.seatNumber} Seats</small>
          <div
            className="pt-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="text-primary" style={{ fontSize: 25 }}>
              N$
              <div
                className="d-inline"
                style={{ color: "#132649", fontFamily: "Circular Std" }}
              >
               {data?.mileageCapPerDay?.amount ? data?.mileageCapPerDay?.amount : 0}
              </div>
              <div
                className="d-inline"
                style={{ color: "#132649", fontSize: 17, fontWeight: 500 }}
              >
                /day
              </div>
            </div>
            <div
              style={{
                top: 10,
                right: 10,
                borderRadius: 10,
                background: "#132649",
                width: 40,
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                padding: 8,
              }}
            >
              <img src={Image} height={20} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarBox;
