export const config = [
  {
    title: "Dashboard",
    icon: "heroicons-outline:view-grid",
    path: "/dashboard",
  },
  {
    title: "Payment",
    icon: "lets-icons:wallet-alt",
    path: "/payment",
  },
  {
    title: "Notification",
    icon: "clarity:notification-line",
    path: "/notifications",
  },
  {
    title: "Coupons",
    icon: "fluent-emoji-high-contrast:books",
    path: "/coupons",
  },
  {
    title: "Blogs",
    icon: "arcticons:contactdiary",
    path: "/blogs",
  },
  {
    title: "Cancellation Policy",
    icon: "fluent:document-footer-dismiss-24-regular",
    path: "/cancellation-policy",
  },
  {
    title: "Privacy Policy",
    icon: "charm:shield-tick",
    path: "/privacy-policy",
  },
];
