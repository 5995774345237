import { createSlice } from "@reduxjs/toolkit";
import { RENTEDWHEELSAPI } from "../../services/apiConstant";
import api from "../../services";
import toast from "react-hot-toast";

const slice = createSlice({
  name: "notification",
  initialState: {},
  reducers: {},
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

// const { sendNotificationsByAdminSuccess } = slice.actions;

export const sendNotificationsByAdmin = (request, setFormData) => async () => {
  api
    .post(`${RENTEDWHEELSAPI.notification.sendNotificationsByAdmin}`, request)
    .then((response) => {
      console.log("response", response);
      let result = response.data;
      if (result.status === 1) {
        toast.success(response.data.message);
        setFormData({
          msgTitle: "",
          msgDescription: "",
        });
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
