import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLayout from "../layout/auth";
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/forgotPassword.js";
import { loginData } from "../components/constant/index.js";
import NotFoundPage from "../pages/404page/index.js";
// import Dashboard from "../pages/owner/dashBoard";
import AdminLayout from "layout/dashboardLayout";
import Notification from "pages/dashboardItems/notifications";
import Dashboard from "pages/dashboardItems/dashBoard";
import Payment from "pages/dashboardItems/payment";
import Coupons from "pages/dashboardItems/coupons";
import CancellationPolicy from "pages/dashboardItems/cancellationPolicy";
import PrivacyPolicy from "pages/dashboardItems/privacyPolicy";
import Blogs from "pages/dashboardItems/blogs";
import EditPrivacyPolicy from "pages/dashboardItems/privacyPolicy/editPrivacyPolicy";
import EditCancellationPolicy from "pages/dashboardItems/cancellationPolicy/editCancellationPolicy";
import CreateBlogs from "pages/dashboardItems/blogs/createBlogs";
import BlogDetails from "pages/dashboardItems/blogs/blogDetails";
import CreateCoupons from "pages/dashboardItems/coupons/createCoupons";
import Withdraw from "pages/dashboardItems/payment/withdraw";
import TotalRevenue from "pages/dashboardItems/dashBoard/totalRevenue";
import TotalRentals from "pages/dashboardItems/dashBoard/totalRentals";
import TotalCommission from "pages/dashboardItems/dashBoard/totalCommission";
import TotalUsers from "pages/dashboardItems/dashBoard/totalUsers";
import RentalDetails from "pages/dashboardItems/dashBoard/rentalDetails";
import UpdateRentalProfile from "components/dialog/updateRentalProfile";

export default function Router() {
  const routes = useRoutes([
    // AdminLayout routing
    {
      path: "/",
      element: loginData ? <AdminLayout /> : <Navigate to="/auth/login" />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        { path: "notifications", element: <Notification /> },
        { path: "/payment", element: <Payment /> },
        { path: "/withdraw", element: <Withdraw /> },
        { path: "/coupons", element: <Coupons /> },
        { path: "/create-coupons", element: <CreateCoupons /> },
        { path: "/update-coupons/:id", element: <CreateCoupons /> },
        { path: "/cancellation-policy", element: <CancellationPolicy /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/total-revenue", element: <TotalRevenue /> },
        { path: "/total-rentals", element: <TotalRentals /> },
        { path: "/total-users", element: <TotalUsers /> },
        { path: "/total-comission", element: <TotalCommission /> },
        { path: "/rental-details/:id", element: <RentalDetails /> },
        

        { path: "/edit-PrivacyPolicy", element: <EditPrivacyPolicy /> },
        { path: "/edit-PrivacyPolicy/:id", element: <EditPrivacyPolicy /> },

        {
          path: "/edit-CancellationPolicy",
          element: <EditCancellationPolicy />,
        },
        {
          path: "/edit-CancellationPolicy/:id",
          element: <EditCancellationPolicy />,
        },
        { path: "/blogs", element: <Blogs /> },
        { path: "/add-blogs", element: <CreateBlogs /> },
        {
          path: "/edit-blogs/:id",
          element: <CreateBlogs />,
        },
        { path: "/blog-details/:id", element: <BlogDetails /> },
      ],
    },
    // Auth routing
    {
      path: "/auth",
      element: loginData ? <Navigate to="/dashboard" /> : <AuthLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
      ],
    },

    // 404 page
    { path: "*", element: <NotFoundPage /> },
  ]);

  return routes;
}

// import { Navigate, useRoutes } from "react-router-dom";
// import AuthLayout from "../layout/auth";
// import Login from "../pages/auth/login";
// import ForgotPassword from "../pages/auth/forgotPassword.js";
// // import Register from "../pages/auth/register/register.js";
// import { loginData } from "../components/constant/index.js";
// import NotFoundPage from "../pages/404page/index.js";
// import CarOwnerLayout from "layout/carOwner";
// import Dashboard from "pages/owner/dashBoard";

// export default function Router() {
//   const routes = useRoutes([
//     // -------------------   Auth routing -----------------------
//     {
//       path: "/auth",
//       element: !loginData ? <AuthLayout /> : <Navigate to="/" />,
//       children: [
//         { path: "login", element: <Login /> },
//         { path: "forgotPassword", element: <ForgotPassword /> },
//         // { path: "signUp", element: <Register /> },
//       ],
//     },
//     // -------------------   carOwner routing -----------------------
//     {
//       path: "/car-owner",
//       element: loginData ? <CarOwnerLayout /> : <Navigate to="/" />,
//       children: [{ path: "/car-owner/dashboard", element: <Dashboard /> }],
//     },
//     { path: "*", element: <NotFoundPage /> },
//   ]);

//   return routes;
// }
