import React, { useEffect } from "react";
import OwnerHeader from "layout/dashboardLayout/header";
import image from "../../../assets/img/paymentBg.png";
import "./style.css";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "themes/useResponsive";
import { getTheListOfPolicy } from "store/slice/policy";
import { useDispatch, useSelector } from "react-redux";

const CancellationPolicy = () => {
  const { screenType } = useResponsive();
  const dispatch = useDispatch();
  const { getPolicy } = useSelector((state) => state.policy);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    let request = {
      policyType: 2,
      language: "en",
    };

    dispatch(getTheListOfPolicy(request));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const isDescriptionBlank =
    !getPolicy?.description || getPolicy?.description.trim() === "";

  return (
    <>
      <OwnerHeader title={"Cancellation Policy"} />
      <div
        className="card mt-5"
        style={{
          background: "linear-gradient(to right, #FFF5E6, #EAF6F4 50%)",
          width: "100%",
          height: "150px",
          border: "none",
          marginTop: "10",
        }}
      >
        <div className="d-flex justify-content-center align-items-center my-auto">
          <center className="me-2">
            <h4
              style={{
                color: "#00071A",
                fontFamily: "Circular Std Bold",
                fontSize: screenType === "MOBILE" ? "1.2rem" : "2rem",
                fontWeight: 40,

                marginRight: screenType === "MOBILE" ? "3rem" : "",
              }}
            >
              Cancellation Policy
            </h4>
          </center>
          {screenType === "MOBILE" || screenType === "TABLET" ? (
            <div
              type="button"
              style={{
                background: "#FFBB51",
                borderRadius: 35,
                height: 40,
                width: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon
                onClick={() => navigate("/edit-CancellationPolicy")}
                icon="gravity-ui:pencil-to-line"
                color="white"
                height={20}
              />
            </div>
          ) : (
            <button
              onClick={() => navigate("/edit-CancellationPolicy")}
              style={{
                position: "absolute",
                bottom: "3.5rem",
                right: "3rem",
                fontFamily: "Circular Std Black",
              }}
              className="btn btn-primary btn-sm"
            >
              Edit Policy
            </button>
          )}
        </div>
      </div>
      <div className="container">
        <div>
          <h4 className="paragraphH4">{getPolicy?.title}</h4>
          {/* <div className="H4detail" >{getPolicy?.description}</div> */}
          <div
            className="H4detail"
            dangerouslySetInnerHTML={{ __html: getPolicy?.description }}
          />
        </div>
        {/* <div className="row mx-0"></div>
        <div className="mt-5 mb-5 H4detail"></div> */}
      </div>
    </>
  );
};

export default CancellationPolicy;
