import React from "react";
import OwnerHeader from "layout/dashboardLayout/header";
import { useResponsive } from "themes/useResponsive";

const Withdraw = () => {
  const { screenType } = useResponsive();
  return (
    <>
      <OwnerHeader
        title={"Withdraw"}
        previousPage={"Payment"}
        currentPage={"Withdraw"}
        previousPageUrl={"/payment"}
      />
      <div
        className="card"
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "150px",
          marginTop: "50px",
        }}
      >
        <div>
          <center>
            <h4
              style={{
                color: "#13264994",
                fontFamily: "Circular Std Book",
                fontSize: "20px",
                paddingTop: 20,
              }}
            >
              Refundable Amount
            </h4>
            <h2
              style={{
                color: "#132649",
                fontFamily: "Circular Std Medium",
                fontSize: "75px",
              }}
            >
              <span
                style={{ color: "#FFBB51", fontSize: "40px", fontWeight: 600 }}
              >
                N$
              </span>
              675
            </h2>
          </center>

          <div className="row mt-5">
            <div className="col">
              <h4 className="carOwner-tab-title ">Banking Details</h4>
              <label
                htmlFor="inputEmail4"
                className="form-label"
                style={{
                  color: "#132649",
                  fontFamily: "Circular Std Black",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                Account Number
              </label>
              <input type="email" className="form-control" id="inputEmail4" />
              <label
                htmlFor="inputEmail4"
                className="form-label"
                style={{
                  color: "#132649",
                  fontFamily: "Circular Std Black",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                Confirm Account Number
              </label>
              <input type="email" className="form-control" id="inputEmail4" />
              <label
                htmlFor="inputEmail4"
                className="form-label"
                style={{
                  color: "#132649",
                  fontFamily: "Circular Std Black",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                IFSC Code
              </label>
              <input type="email" className="form-control" id="inputEmail4" />
              <label
                htmlFor="inputEmail4"
                className="form-label"
                style={{
                  color: "#132649",
                  fontFamily: "Circular Std Black",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                Accounts Holder Name
              </label>
              <input type="email" className="form-control" id="inputEmail4" />
              <div
                className="d-grid gap-2 col-8"
                style={{
                  marginTop: "20px",
                }}
              >
                <button
                  className="btn btn-warning"
                  type="button"
                  style={{
                    fontFamily: "Circular Std Black",
                    width: "250px",
                  }}
                >
                  Withdrawn
                </button>
              </div>
            </div>
            <div
              className={`${screenType === "MOBILE" ? "col mt-5" : "col"}  `}
            >
              <h4 className="carOwner-tab-title">Banks</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
