import { useEffect, useState } from "react";
import OwnerHeader from "../../../layout/dashboardLayout/header";
import Input from "components/input";
import ColoredButton from "components/button/colorFullButtons";
import { sendNotificationsByAdmin } from "store/slice/notification";
import { useDispatch } from "react-redux";

export default function Notification() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    msgTitle: "",
    msgDescription: "",
  });
  const [formError, setFormError] = useState({});
  const handleSubmit = (type) => {
    const { msgTitle, msgDescription } = formData;
   if (msgTitle.trim().length == 0)  {
      setFormError((formError) => ({
        ...formError,
        msgTitleErr: "Please add title here",
      }));
      return;
    }
    if (!msgTitle) {
      setFormError((formError) => ({
        ...formError,
        msgTitleErr: "Please add title here",
      }));
      return;
    }
if(msgDescription.trim().length == 0){
  setFormError((formError) => ({
    ...formError,
    msgDescriptionErr: "Please add description",
  }));
  return;
}
    if (!msgDescription) {
      setFormError((formError) => ({
        ...formError,
        msgDescriptionErr: "Please add description",
      }));
      return;
    }
    sendNotification(type);
  };
  const sendNotification = (type) => {
    let request = {
      language: "en",
      msgTitle: formData?.msgTitle,
      msgDescription: formData?.msgDescription,
      type: type, //"3 for user || 2 for car-owner",
    };
    dispatch(sendNotificationsByAdmin(request, setFormData));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("formdata", formData);
  return (
    <>
      <OwnerHeader title={"Create Notification"} />
      <div className="my-5">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div
              className="mt-3"
              style={{
                fontFamily: "Circular Std Book Italic",
                color: "#8992a4",
              }}
            >
              Create your notification for rental and user
            </div>
            <div className="myCar-label mt-3">Title</div>
            <div className="form-group pt-3 ">
              <Input
                name="mainTitle"
                value={formData?.msgTitle}
                onChange={(val) => {
                  setFormData({ ...formData, msgTitle: val });
                  setFormError((formError) => ({
                    ...formError,
                    msgTitleErr: "",
                  }));
                }}
              />
              {formError?.msgTitleErr && (
                <span className="text-danger">{formError?.msgTitleErr}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="myCar-label">Your message</div>

            <div className="form-group pt-3">
              <textarea
                name="msgDescription"
                value={formData?.msgDescription}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="8"
                onChange={(val) => {
                  setFormData({
                    ...formData,
                    msgDescription: val.target.value,
                  });
                  setFormError((formError) => ({
                    ...formError,
                    msgDescriptionErr: "",
                  }));
                }}
              ></textarea>
            </div>
            {formError?.msgDescriptionErr && (
              <span className="text-danger">
                {formError?.msgDescriptionErr}
              </span>
            )}
          </div>

          <div
            className="d-grid gap-2 col-8"
            style={{
              marginTop: "20px",
            }}
          >
            <ColoredButton
              onClick={() => handleSubmit(2)}
              title={"Send to Rental"}
              style={{ background: "#FFBB51", width: "20rem" }}
            />

            <ColoredButton
              onClick={() => handleSubmit(3)}
              title={"Send to User"}
              style={{ background: "#6CD2C2", width: "20rem" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
