import { createSlice } from "@reduxjs/toolkit";
import { RENTEDWHEELSAPI } from "../../services/apiConstant";
import api from "../../services";
import toast from "react-hot-toast";

const slice = createSlice({
  name: "policy",
  initialState: {
    getPolicy: [],
    setPolicy: [],
  },
  reducers: {
    getTheListOfPolicySuccess: (state, action) => {
      state.getPolicy = action.payload;
    },
    setPolicyByAdminSuccess: (state, action) => {
      state.setPolicy = action.payload;
    },
  },
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

const { getTheListOfPolicySuccess, setPolicyByAdminSuccess } = slice.actions;

export const getTheListOfPolicy = (request) => async (dispatch) => {
  api
    .get(
      `${RENTEDWHEELSAPI.policy.getTheListOfPolicy}/?policyType=${request?.policyType}&language=${request?.language}`,
      request
    )
    .then((response) => {
      console.log("response", response);
      let result = response.data;
      if (result.status === 1) {
        dispatch(getTheListOfPolicySuccess(result.data));
      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};
export const setPolicyByAdmin = (request, navigate) => async (dispatch) => {
  api
    .post(`${RENTEDWHEELSAPI.policy.setPolicyByAdmin}`, request)
    .then((response) => {
      console.log("response", response);
      let result = response.data;
      if (result.status === 1) {
        if (request.policyType === 1) {
          navigate("/privacy-policy");
        } else if (request.policyType === 2) {
          navigate("/cancellation-policy");
        }
        dispatch(setPolicyByAdminSuccess(result.data));
        toast.success(result.message);

      } else {
        toast.error(result.message);
      }
    })
    .catch(() => {});
};

// export const setPolicyByAdmin = (request, navigate) => async (dispatch) => {
//   api
//     .post(`${RENTEDWHEELSAPI.policy.setPolicyByAdmin}`, request)
//     .then((response) => {
//       console.log("response", response);
//       let result = response.data;
//       if (result.status === 1) {
//         navigate("/privacy-policy");
//         dispatch(setPolicyByAdminSuccess(result.data));
//       } else {
//         toast.error(result.message);
//       }
//     })
//     .catch(() => {});
// };
